import { scheduleSBPIdLifePage2 } from "src/constants/Schedules";
import {
    Company,
    FolderNode,
    ReturnNode,
    ScheduleType,
    State,
} from "src/types";

export function isCompanyInformationFolderNode(
    folderNode: FolderNode
): boolean {
    return folderNode?.attributes?.displayName === "Company Information";
}

export function isSecondPageSBPReturnNode(returnNode: ReturnNode): boolean {
    return returnNode?.id === scheduleSBPIdLifePage2;
}

export function isReturnNodeActive(returnNode: ReturnNode): boolean {
    return returnNode?.isActive;
}

export function hasReturnNodeScheduleTypeReturn(
    returnNode: ReturnNode
): boolean {
    return returnNode?.scheduleType === ScheduleType.RETURN;
}
/**
 * This function detect if the folder belongs to the state of domicile, it has two options depending if is Company View or State View
 */
export function isDomicileFolderNode(
    folderNode,
    company?: Company,
    state?: State
) {
    if (company && !state) {
        return (
            folderNode?.id?.toString() === company?.stateOfDomicile?.toString()
        );
    }
    if (state && !company) {
        return folderNode.stateOfDomicile?.toString() === state.id?.toString();
    }
    return false;
}

export function isRetalReturnOrRetalWorksheet(
    returnNode: ReturnNode,
    folderNode: FolderNode
): boolean {
    return (
        returnNode?.retalFolderId !== folderNode?.id ||
        returnNode?.retalFolderId === "108"
    );
}

export function isDomesticReturnNode(returnNode: ReturnNode): boolean {
    return returnNode?.isDomestic;
}

export function isForeignReturnNode(returnNode: ReturnNode): boolean {
    return returnNode?.isForeign;
}

/**
 * This function contain all the filters that need to be applied to the return nodes before rendering them.
 * @param folderNode
 * @param company
 * @returns boolean -- true if should be rendered, false if should not be rendered
 */
export function filterForReturnNodes(
    returnNode: ReturnNode,
    folderNode: FolderNode,
    company?: Company,
    includeInactive: boolean = false,
    includeRetal: boolean = false,
    state?: State
): boolean {
    if (
        returnNode.id == "4" &&
        returnNode.scheduleType !== ScheduleType.RETURN
    ) {
        return true;
    }
    if (
        isCompanyInformationFolderNode(folderNode) &&
        isSecondPageSBPReturnNode(returnNode)
    ) {
        return false;
    }

    if (returnNode.scheduleType === ScheduleType.RETURN) {
        // Do not render inactive return nodes
        if (includeInactive || isReturnNodeActive(returnNode)) {
            if (
                includeRetal ||
                !isRetalReturnOrRetalWorksheet(returnNode, folderNode)
            ) {
                return isDomicileFolderNode(folderNode, company, state)
                    ? isDomesticReturnNode(returnNode) // Include only domestic returns if the folder node is the domicile folder node
                    : isForeignReturnNode(returnNode); // Include only foreign returns if the folder node is not the domicile folder node
            } else return false; // Exclude Retal return and Retal worksheet
        } else return false; // Exclude inactive return nodes
    } else return true; // Include all non-return nodes
}

/**
 * Apply filters to the folder nodes before rendering them.
 * @param folderNode
 * @returns boolean -- true if should be rendered, false if should not be rendered
 */
export function filterForFolderNodes(folderNode: FolderNode) {
    // Filter the folders that are not common
    return folderNode?.attributes?.displayName !== "Common";
}

/**
 * Check if a URL is a valid web link.
 * This function verifies that the given URL starts with a valid protocol (`http`, `https`, or `ftp`)
 * and follows the structure of a proper web address.
 *
 * @param url The URL to check. It should be a string representing the web address.
 * @returns boolean -- true if the URL is valid (starts with `http`, `https`, or `ftp`),
 * false otherwise (e.g., invalid format, missing protocol, or unsupported protocol).
 */
export function isValidWebLink(url: string): boolean {
    if (typeof url !== "string") {
        return false; // If the input is not a string, return false.
    }

    const regex = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return regex.test(url);
}
