import React from "react";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorJob, AllocatorJobsPayload, AllocatorCompany, FixMeLater } from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StyledFormControlDropdown } from "../../Allocator.styled";

interface CompanyDropdownProps {
    userCompanies: AllocatorCompany[];
    isDragActive: boolean;
    fetchJobList: (filterData?: AllocatorJobsPayload | null, pageNumber?: number, company?: AllocatorCompany) => Promise<AllocatorJob[]>;
}

const CompanyDropdown: React.FC<CompanyDropdownProps> = ({
    userCompanies,
    isDragActive,
    fetchJobList,
}) => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedCompany: AllocatorCompany = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company,
    );
    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );
    const filterState: AllocatorJobsPayload = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.filterState
    );

    const dispatch = useAppDispatch();

    const handleChange = async (event: SelectChangeEvent<number>) => {
        const selectedCompany = userCompanies?.find(comp => comp?.id === event?.target?.value);
        if (page) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsPage(0)
            );
        }
        dispatch(
            GlobalStateActions[product?.productName].setCompany(
                selectedCompany
            ),
        );
        if (filterState === undefined) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters: [], searchPhrase: ""})
            );
        }
        await fetchJobList(null, 0, selectedCompany);
    };

    if (isDragActive) return null;

    return (
        <StyledFormControlDropdown size="small">
            <InputLabel>Company</InputLabel>
            <Select
                value={selectedCompany?.id ?? ""}
                label="Company"
                onChange={handleChange}
            >
                {
                    userCompanies?.map((company) => {
                        return (
                            <MenuItem value={company?.id} key={company?.id}>{company?.abbr}</MenuItem>
                        )
                    })
                }
            </Select>
        </StyledFormControlDropdown>
    );
};

export default CompanyDropdown;
