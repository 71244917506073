import FeatureFlags from "src/utils/FeatureFlags";
import { handlePrintExport } from "./PrintExport.util";
import { Save as SaveIcon, Print as PrintIcon } from "@mui/icons-material";
import { ToolbarItem, ToolbarItemName } from "src/types";

export const getToolbarIcons = (
    apiRef,
    documentKey,
    handleSnackbar,
    onSaveClick,
    pendingChanges,
): ToolbarItem[] => {
    // Toolbar icon data here
    const iconData = [
        {
            position: 1,
            icon: SaveIcon,
            title: ToolbarItemName.SAVE,
            onClick: onSaveClick,
            isEnabled: true,
            isActive: pendingChanges,
            isValid: true,
        },
    ];
        iconData.push({
            position: iconData.length + 1,
            icon: PrintIcon,
            title: ToolbarItemName.PRINT,
            onClick: async () =>
                handlePrintExport(apiRef, documentKey, handleSnackbar),
            isEnabled: true,
            isActive: true,
            isValid: true,
        });

    return iconData;
};
