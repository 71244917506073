import { Button, Checkbox, Modal, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { emailRegex, onlyLettersRegex, phoneRegex } from "src/constants/Regex";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { Account, FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../AccountAdmin.styled";
import "./NewAccountModal.scss";

const NewAccountModal: FC<{ open: boolean; onClose: () => void }> = ({
    open,
    onClose,
}) => {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | string[]>(
        "Account created successfully!"
    );
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const dispatch = useAppDispatch();
    const product: FixMeLater = useAppSelector((state) => state.Product?.value);
    const accounts = useAppSelector(
        (state) => state?.[product?.productName].value?.accounts
    );

    const accountService = AccountService.getInstance();

    const initialFormData: Account = {
        name: "",
        accountNumber: "",
        isFederated: false,
        sinceStratusDate: "",
        customerSince: "",
        clientContactDTO: {
            clientContactName: "",
            clientContactEmail: "",
            clientContactPhone: "",
        },
        parentId: "",
        csrId: "",
        logoMetadata: {
            id: "",
            store: "",
        },
        active: true,
    };

    const [formData, setFormData] = useState<Account>(initialFormData);

    const [validationErrors, setValidationErrors] = useState({
        name: "",
        accountNumber: "",
        "clientContactDTO.clientContactEmail": "",
        "clientContactDTO.clientContactPhone": "",
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "name" && value.length > 65) {
            setValidationErrors({
                ...validationErrors,
                name: "Name must be at most 65 characters.",
            });
        } else if (
            name === "accountNumber" &&
            (value.length < 5 ||
                value.length > 7 ||
                !onlyLettersRegex.test(value.charAt(0)))
        ) {
            setValidationErrors({
                ...validationErrors,
                accountNumber:
                    "Account Number must be at least 5 at most 7 characters and should start with a letter.",
            });
        } else if (
            name === "clientContactDTO.clientContactPhone" &&
            !phoneRegex.test(value)
        ) {
            setValidationErrors({
                ...validationErrors,
                [name]: "Phone Number must be 10 digits and contain no special characters.",
            });
        } else if (
            name === "clientContactDTO.clientContactEmail" &&
            !emailRegex.test(value)
        ) {
            setValidationErrors({
                ...validationErrors,
                [name]: "Email address is invalid.",
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                [name]: "",
            });
        }

        if (name.includes(".")) {
            const [fieldName, subFieldName] = name.split(".");
            setFormData({
                ...formData,
                [fieldName]: {
                    ...formData[fieldName],
                    [subFieldName]: value,
                },
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCheckBoxChange = (name: string, checked: boolean) => {
        setFormData({
            ...formData,
            [name]: checked,
        });
    };

    const handleSnackbar = (message: string | string[], severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (
            validationErrors.name ||
            validationErrors.accountNumber ||
            validationErrors["clientContactDTO.clientContactEmail"] ||
            validationErrors["clientContactDTO.clientContactPhone"]
        ) {
            return;
        }

        try {
            setIsLoading(true);

            const response = await accountService.createAccount({
                ...formData,
                active: true,
            });

            dispatch(
                GlobalStateActions[product?.productName].setSelectedAccount(
                    response
                )
            );

            const updatedAllAccounts = [...accounts, response].sort((a, b) =>
                a.name.localeCompare(b.name)
            );

            dispatch(
                GlobalStateActions[product?.productName].setAccounts(
                    updatedAllAccounts
                )
            );

            onClose();
            setFormData(initialFormData);
            handleSnackbar("Account created successfully!", "success");
        } catch (error: FixMeLater) {
            if (
                error.body &&
                JSON.parse(error.body).message === "Entity already exists"
            ) {
                handleSnackbar(
                    "An account with this account number already exists. Please check the account number and try again.",
                    "error"
                );
            } else {
                handleSnackbar(error?.message as string, "error");
            }
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">
                                New Account Information
                            </Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <StyledFlexContainer>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Account Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.name}
                                        helperText={validationErrors.name}
                                    />
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="accountNumber"
                                        label="Account Number"
                                        name="accountNumber"
                                        value={formData.accountNumber}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.accountNumber}
                                        helperText={
                                            validationErrors.accountNumber
                                        }
                                    />
                                </StyledFlexContainer>

                                <StyledFlexContainer>
                                    <Checkbox
                                        checked={!!formData.isFederated}
                                        onChange={(e) =>
                                            handleCheckBoxChange(
                                                "isFederated",
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <label>Federated</label>
                                </StyledFlexContainer>

                                <TextField
                                    type="date"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="sinceStratusDate"
                                    InputLabelProps={{ shrink: true }}
                                    label="Stratus Since"
                                    name="sinceStratusDate"
                                    value={formData.sinceStratusDate}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    type="date"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="customerSince"
                                    InputLabelProps={{ shrink: true }}
                                    label="Customer Since"
                                    name="customerSince"
                                    value={formData.customerSince}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="clientContactName"
                                    label="Client Contact Name"
                                    name="clientContactDTO.clientContactName"
                                    value={
                                        formData?.clientContactDTO
                                            ?.clientContactName
                                    }
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="clientContactEmail"
                                    label="Client Contact Email"
                                    name="clientContactDTO.clientContactEmail"
                                    value={
                                        formData?.clientContactDTO
                                            ?.clientContactEmail
                                    }
                                    onChange={handleInputChange}
                                    error={
                                        !!validationErrors[
                                            "clientContactDTO.clientContactEmail"
                                        ]
                                    }
                                    helperText={
                                        validationErrors[
                                            "clientContactDTO.clientContactEmail"
                                        ]
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="clientContactPhone"
                                    label="Client Contact Phone"
                                    name="clientContactDTO.clientContactPhone"
                                    value={
                                        formData?.clientContactDTO
                                            ?.clientContactPhone
                                    }
                                    onChange={handleInputChange}
                                    error={
                                        !!validationErrors[
                                            "clientContactDTO.clientContactPhone"
                                        ]
                                    }
                                    helperText={
                                        validationErrors[
                                            "clientContactDTO.clientContactPhone"
                                        ]
                                    }
                                />

                                <StyledFlexContainer justify={"center"}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.name ||
                                            !!validationErrors.accountNumber ||
                                            !!validationErrors[
                                                "clientContactDTO.clientContactEmail"
                                            ] ||
                                            !!validationErrors[
                                                "clientContactDTO.clientContactPhone"
                                            ]
                                        }
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </>
    );
};

export default NewAccountModal;
