import React from "react";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorJob, AllocatorJobsPayload, AllocatorCompany, FixMeLater } from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StyledFormControlDropdown } from "../../Allocator.styled";

interface YearDropdownProps {
    supportedYears: number[];
    isDragActive: boolean;
    fetchJobList: (
        filterData?: AllocatorJobsPayload | null, 
        pageNumber?: number, 
        company?: AllocatorCompany | null, 
        year?: number
    ) => Promise<AllocatorJob[]>;
}

const YearDropdown: React.FC<YearDropdownProps> = ({
    supportedYears,
    isDragActive,
    fetchJobList,
}) => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const year: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.year,
    );
    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );
    const filterState: AllocatorJobsPayload = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.filterState
    );

    const dispatch = useAppDispatch();

    const handleChange = async (event: SelectChangeEvent<number>) => {
        const selectedYear = supportedYears?.find(year => year === event?.target?.value);
        if (page) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsPage(0)
            );
        }
        dispatch(
            GlobalStateActions[product?.productName].setYear(
                selectedYear
            ),
        );
        if (filterState === undefined) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters: [], searchPhrase: ""})
            );
        }
        await fetchJobList(null, 0, null, selectedYear);
    };

    if (isDragActive) return null;

    return (
        <StyledFormControlDropdown size="small">
            <InputLabel>Year</InputLabel>
            <Select
                value={year}
                label="Year"
                onChange={handleChange}
            >
                {
                    supportedYears?.map((year, index) => {
                        return (
                            <MenuItem value={year} key={index}>{year}</MenuItem>
                        )
                    })
                }
            </Select>
        </StyledFormControlDropdown>
    );
};

export default YearDropdown;
