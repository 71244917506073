import { Box } from "@mui/material";
import { styled } from "styled-components";

export const PaymentInfoStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const PaymentInfoSelectorContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PaymentInfoElectronicPaymentInformation = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;


export const PaymentInfoInstructions = styled(Box)`
    display: flex;
    flex-direction: column;
`;
