import React, { ChangeEvent, useEffect, useState } from "react";
import {
    ActionButtonStyled,
    EfileNaicOptinsActionContainerStyled,
    EfileNaicOptinsStyled,
} from "./EfileNaicOptins.styled";
import {
    LayoutPaneTab as Tab,
    LayoutPaneTabs as Tabs,
    LayoutPaneTabPanel as TabPanel,
} from "src/uikit";
import {
    ArrowBack,
    ArrowCircleLeftOutlined,
    ArrowCircleRightOutlined,
    ArrowForward,
    Info,
    Send,
} from "@mui/icons-material";
import { UseAgreement } from "./Sections/UseAgreement";
import Loader from "../Loader/Loader";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import FeatureFlags from "src/utils/FeatureFlags";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsFormData,
    PAYMENT_TYPE,
} from "./EfileNaicOptins.context";
import { PaymentInfo } from "./Sections/PaymentInfo";
import { CompanyType } from "./Sections/CompanyType";
import { OptinsService } from "src/services/OptinsService";
import { OptinsCompanyType, OptinsPayload } from "src/types";

export type EfileNaicOptinsProps = {
    open: boolean;
    onClose: () => void;
    payload: OptinsPayload;
};

enum EfileNaicOptinsTabs {
    COMPANY_TYPE = 0,
    USE_AGREEMENT = 1,
    PAYMENT_INFO = 2,
}

export const EfileNaicOptins: React.FC<EfileNaicOptinsProps> = ({
    open,
    onClose,
    payload,
}) => {
    const optinsService = OptinsService.getInstance();

    const [tab, setTab] = useState<EfileNaicOptinsTabs>(
        EfileNaicOptinsTabs.COMPANY_TYPE
    );
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [fetchingFilingStatus, setFetchingFilingStatus] =
        useState<boolean>(false);
    const [formData, setFormData] = useState<EfileNaicOptinsFormData>({
        agreement: false,
        paymentType: PAYMENT_TYPE.DEBIT,
        companyTypes: [],
        selectedCompanyType: undefined,
        filingStatus: undefined,
    });

    const [error, setError] = useState<string | null>(null);

    const handleChange = (event, newValue: number) => {
        setTab(newValue);
    };

    const fetchData = async () => {
        setIsFetching(true);
        setError(null);

        try {
            const companyTypes = await optinsService.getCompanyTypesByState(
                payload
            );
            setFormData({
                ...formData,
                companyTypes,
            });
        } catch (err) {
            setError("Failed to fetch company types. Please try again.");
        } finally {
            setIsFetching(false);
        }
    };

    const fetchFilingStatus = async () => {
        setFetchingFilingStatus(true);
        setError(null);

        try {
            const filingStatus = await optinsService.findFilling({
                returnDocumentIdDTO: payload,
                naicCompanyTypeId:
                    formData.selectedCompanyType?.identifier || "",
            });
            setFormData({
                ...formData,
                filingStatus,
            });
        } catch (err) {
            setError("Failed to fetch filing status. Please try again.");
        } finally {
            setFetchingFilingStatus(false);
        }
    };

    const onPrevClick = () => {
        if (tab > EfileNaicOptinsTabs.COMPANY_TYPE) {
            setTab(tab - 1);
        }
    };

    const isNextDisabled = () => {
        if (isFetching) return true;

        switch (tab) {
            case EfileNaicOptinsTabs.COMPANY_TYPE:
                return false;
            case EfileNaicOptinsTabs.USE_AGREEMENT:
                return !formData.agreement;
            case EfileNaicOptinsTabs.PAYMENT_INFO:
                return true;
            default:
                return true;
        }
    };

    const isPrevDisabled = () => {
        if (isFetching) return true;
        return tab === EfileNaicOptinsTabs.COMPANY_TYPE;
    };

    const onNextClick = () => {
        if (tab < EfileNaicOptinsTabs.PAYMENT_INFO) {
            setTab(tab + 1);
        }
    };

    useEffect(() => {
        if (open) {
            setTab(EfileNaicOptinsTabs.COMPANY_TYPE);
            fetchData();
        }
    }, [open]);

    useEffect(() => {
        if (formData.selectedCompanyType) {
            fetchFilingStatus();
        }
    }, [formData.selectedCompanyType]);

    function onChangeTestingYear(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        const testingYear = parseInt(event.target.value);
        setFormData({
            ...formData,
            testingYear,
        });
    }

    return (
        <EfileNaicOptinsContext.Provider
            value={{
                formData,
                setFormData,
                fetchingFilingStatus,
            }}
        >
            <EfileNaicOptinsStyled data-testid="efile-naic-optins">
                {isFetching && <Loader />}
                {error && <div style={{ color: "red" }}>{error}</div>}
                {!isFetching && (
                    <>
                        <Tabs
                            value={tab}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={"auto"}
                            TabIndicatorProps={{
                                children: (
                                    <span className="MuiTabs-indicatorSpan" />
                                ),
                            }}
                            slots={{
                                StartScrollButtonIcon: ArrowCircleLeftOutlined,
                                EndScrollButtonIcon: ArrowCircleRightOutlined,
                            }}
                        >
                            <Tab selectedTab={tab} value={0}>
                                Company Type
                            </Tab>
                            <Tab selectedTab={tab} value={1}>
                                Use Agreement
                            </Tab>
                            <Tab
                                selectedTab={tab}
                                value={2}
                                disabled={formData.agreement ? false : true}
                            >
                                Payment Info
                            </Tab>
                        </Tabs>
                        <TabPanel selectedTab={tab} value={0}>
                            <CompanyType />
                        </TabPanel>
                        <TabPanel selectedTab={tab} value={1}>
                            <UseAgreement />
                        </TabPanel>
                        <TabPanel selectedTab={tab} value={2}>
                            <PaymentInfo />
                        </TabPanel>
                        <EfileNaicOptinsActionContainerStyled>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                }}
                            >
                                <ActionButtonStyled
                                    variant="contained"
                                    color="primary"
                                    onClick={onPrevClick}
                                    disabled={isPrevDisabled()}
                                    startIcon={<ArrowBack />}
                                >
                                    Prev
                                </ActionButtonStyled>
                                <ActionButtonStyled
                                    variant="contained"
                                    color="primary"
                                    onClick={onNextClick}
                                    disabled={isNextDisabled()}
                                    endIcon={<ArrowForward />}
                                >
                                    Next
                                </ActionButtonStyled>
                            </Box>
                            {FeatureFlags.showOnlyInLocalDevAndQa() && (
                                <TextField
                                    sx={{
                                        width: "150px",
                                    }}
                                    label="Testing Year"
                                    variant="outlined"
                                    size="small"
                                    onChange={onChangeTestingYear}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip
                                                title="Testing Year (only in Dev & QA) "
                                                placement="top"
                                            >
                                                <Info
                                                    sx={{
                                                        fill: "#354560",
                                                    }}
                                                />
                                            </Tooltip>
                                        ),
                                    }}
                                />
                            )}

                            <ActionButtonStyled
                                variant="contained"
                                color="primary"
                                onClick={onClose}
                                endIcon={<Send />}
                                disabled={formData.agreement ? false : true}
                            >
                                Submit
                            </ActionButtonStyled>
                        </EfileNaicOptinsActionContainerStyled>
                    </>
                )}
            </EfileNaicOptinsStyled>
        </EfileNaicOptinsContext.Provider>
    );
};
