import React, { useContext } from "react";
import {
    UseAgreementContentStyled,
    UseAgreementStyled,
} from "./UseAgreement.styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsContextState,
} from "../EfileNaicOptins.context";

export const UseAgreement: React.FC = () => {
    const EfileNaicOptinsCtx: EfileNaicOptinsContextState | undefined =
        useContext(EfileNaicOptinsContext);

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (EfileNaicOptinsCtx) {
            EfileNaicOptinsCtx.setFormData({
                ...EfileNaicOptinsCtx.formData,
                agreement: event.target.checked,
            });
        }
    };

    return (
        <UseAgreementStyled>
            <UseAgreementContentStyled>
                <center>
                    <strong>E-File Information</strong>
                </center>
                <p>
                    This wizard enables you to electronically file the selected
                    return via the NAIC OPTins service.
                </p>
                <p>
                    In order to use this service you must first register and
                    configure your companies on the NAIC OPTins website:
                </p>
                <p>
                    <a href="http://www.optins.org/">
                        Click here [http://www.optins.org/]
                    </a>
                    to visit the OPTins web site.
                </p>
                <br></br>
                <center>
                    <strong>OPTins Use Agreement</strong>
                </center>
                <p>
                    {`
                        OPTins is the property of the NAIC. This Use Agreement is the
                    exclusive statement of the terms under which the NAIC licenses
                    OPTins to a filer ("you"). The NAIC does not guarantee
                    uninterrupted access to the Product. The NAIC has MADE NO
                    WARRANTY OR PROMISE, EITHER EXPRESS OR IMPLIED, INCLUDING BUT
                    NOT LIMITED TO THE IMPLIED WARRANTY OF MERCHANTABILITY AND
                    FITNESS FOR A PARTICULAR PURPOSE. THE NAIC SHALL NOT BE LIABLE
                    FOR AND YOU EXPRESSLY WAIVE, ANY CLAIM FOR ANY INDIRECT LOSS,
                    COST, OR INJURY (INCLUDING, BUT NOT LIMITED TO: LOST SALES, LOST
                    PROFIT, BUSINESS INTERRUPTION OR THIRD PARTY CLAIMS) SUFFERED BY
                    YOU AS A RESULT OF RELIANCE ON OR USE OF THE PRODUCT. You assume
                    sole responsibility for your use of the application and agree to
                    hold the NAIC harmless against any liability or claim
                    attributable to improper use of the application by you or your
                    subsidiaries or affiliates.
                    `}
                </p>
                <p>
                    {`
                        You shall pay a transaction fee for use of the application. Such
                        transaction fee will be disclosed prior to submission and is
                        determined at the sole discretion of the NAIC. This Agreement
                        authorizes the NAIC, upon receipt of appropriate account
                        information, to initiate debit entries and, if necessary, credit
                        entries and adjustments through the Automated Clearing House
                        ("ACH") Operating System for that purpose.
                    `}
                </p>
                <p>
                    {`
                    You agree that you will maintain sufficient funds in the account
                    identified to cover any amounts due to the applicable state
                    agency and any NAIC transaction fees incurred and will indemnify
                    the NAIC for any obligations, indebtedness or liability owed to
                    the NAIC's financial institution or any state agency caused by
                    your failure to have sufficient funds. You shall reimburse the
                    NAIC for any obligations incurred within five days, including
                    any penalties or other charges.
                    `}
                </p>
                <p>
                    {`
                        By clicking on the "Agree" button below, you affirm that the
                        individual accepting these terms is legally authorized to bind
                        the filer being represented. You agree you are authorized by the
                        NAIC to access OPTins and its contents for permitted purposes
                        only. You further acknowledge that you will only access content
                        that you have the proper authority to view and use and that you
                        have verified this authority with the NAIC. You agree that you
                        will not reverse engineer, reverse assemble or reverse compile
                        the application. You also agree not to make available all or any
                        portion of the application to any third party and you recognize
                        that unauthorized or improper access, use or dissemination of
                        materials or modification of the system may result in immediate
                        termination of access and possibly other disciplinary and/or
                        legal action.
                    `}
                </p>
            </UseAgreementContentStyled>
            <FormControlLabel
                control={
                    <Checkbox
                        name="agreement"
                        checked={
                            EfileNaicOptinsCtx?.formData.agreement || false
                        }
                        onChange={handleCheckboxChange}
                    />
                }
                label="I agree to the terms of the NAIC OPTins Use Agreement"
            />
        </UseAgreementStyled>
    );
};
