import * as React from "react";

import { StyledLayoutPaneTabs } from "./Tabs.styled";


export const LayoutPaneTabs = ({children, ...props}) => {
    return (
        <StyledLayoutPaneTabs {...props}>
            {children}
        </StyledLayoutPaneTabs>
    );
}