import { useAuth0 } from "@auth0/auth0-react";
import {
    ChevronRightOutlined,
    RadioButtonCheckedOutlined,
    RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { FC, useState } from "react";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import {
    GraphqlService,
    ReturnDocumentService,
    ReturnPDFService,
    ScheduleService,
} from "src/services";
import {
    returnCompaniesTreeQuery,
    returnTreeQuery,
} from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { ReturnStatusService } from "src/services/ReturnStatusService";
import { getFirstPathSegment, getModuleId } from "src/services/Utility";
import {
    CreateReturnPDFPayload,
    FixMeLater,
    Product,
    QueryParams,
    ReturnNode,
    ScheduleStatus,
    TabProperties,
    Tree,
    User,
} from "src/types";
import { Modal } from "src/uikit";
import { downloadFile, generateUniqueKey, getQueryParams } from "src/utils";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import { StyledFlexContainer } from "../FolderNodeDialog/FolderNodeDialog.styled";
import ImportPremiumData from "../ImportPremiumData/ImportPremiumData";
import ImportSBPData from "../ImportSBP/ImportSBPData";
import "./ReturnNodeMenu.scss";

interface ReturnNodeMenuProps {
    anchorEl: null | HTMLElement;
    handleCloseReturnNodeMenu: (Event) => void;
    returnNode: FixMeLater;
    folderNode: FixMeLater;
}

const ReturnNodeMenu: FC<ReturnNodeMenuProps> = ({
    anchorEl,
    handleCloseReturnNodeMenu,
    returnNode,
    folderNode,
}) => {
    const [isLockLoading, setIsLockLoading] = useState<boolean>(false);
    const [isDeactivateLoading, setDeactivateLoading] =
        useState<boolean>(false);
    const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
        useState<boolean>(false);
    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
    const [importSBPModalOpen, setImportSBPModalOpen] =
        useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | string[]>(
        "Return node updated successfully!"
    );
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");
    const [snackbarDuration, setSnackbarDuration] = useState<number>(3000);

    const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
    const [returnNodeStatus, setReturnNodeStatus] = useState<{
        [key: number]: string;
    }>({});

    const returnStatusService = ReturnStatusService.getInstance();

    const scheduleService = ScheduleService.getInstance();

    const dispatch = useAppDispatch();

    const returnPDFService = ReturnPDFService.getInstance();
    const graphqlService = GraphqlService.getInstance();

    const [downloadAsPdfLoading, setDownloadAsPdfLoading] =
        useState<boolean>(false);

    const { getAccessTokenSilently } = useAuth0();
    const returnDocumentService = ReturnDocumentService.getInstance();

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product.value
    );

    if (!product) {
        console.error("Product is undefined");
        return null;
    }
    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev
    );
    const municipalTree: Tree = useAppSelector(
        (state) => state?.Municipal?.value?.tree
    );

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs
    )?.tabsProperties;

    if (!product) {
        console.error("Product is undefined");
        return null;
    }
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );
    const state = useAppSelector(
        (state) => state?.[product?.productName]?.value?.state
    );
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );
    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });
    const self: User | undefined = useAppSelector(
        (state) => state?.Self
    )?.value;

    const tabKey = generateUniqueKey(
        folderNode?.id.toString(),
        returnNode,
        product,
        company?.name,
        municipalState
    );

    const pendingChangesOnTab = (key: string) => {
        return self?.pendingChanges?.find((tab) => tab === key);
    };

    const handleSnackbar = (
        message: string | string[],
        severity: string,
        autoHideDuration: number = 3000
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setSnackbarDuration(autoHideDuration);
    };
    const updateLockedInTabState = (tabKey: string, locked: boolean) => {
        if (tabsProperties[tabKey]) {
            const updatedTabProperties: TabProperties = {
                ...tabsProperties[tabKey],
                isLocked: locked,
            };
            dispatch(GlobalStateActions.setTab(updatedTabProperties));
        }
    };

    const updateReturnStatusInTabState = (
        tabKey: string,
        returnStatus: number
    ) => {
        if (tabsProperties[tabKey]) {
            const updatedTabProperties: TabProperties = {
                ...tabsProperties[tabKey],
                returnStatus: Number(returnStatus),
            };
            dispatch(GlobalStateActions.setTab(updatedTabProperties));
        }
    };

    const handleLock = async (e) => {
        e.stopPropagation();

        const key = generateUniqueKey(
            folderNode?.id.toString(),
            returnNode,
            product,
            company?.name,
            municipalState
        );

        if (pendingChangesOnTab(key)) {
            alert(
                "Document has unsaved changes! Please save the document before locking."
            );
            handleCloseReturnNodeMenu(e);
            return;
        }

        setIsLockLoading(true);

        try {
            if (returnNode?.scheduleType === "RETURN") {
                const params: QueryParams = getQueryParams({
                    companyId: dropdownStateView ? folderNode?.id : company?.id,
                    productId: ProductNameToIdMap.get(product?.productName),
                    taxYear: product?.taxYear,
                    folderId: dropdownStateView ? state?.id : folderNode?.id,
                    moduleId: getModuleId(
                        product,
                        company
                            ? dropdownStateView
                                ? folderNode
                                : company
                            : {},
                        municipalState
                    ),
                    returnId: returnNode?.id,
                    retalFolderId: returnNode?.retalFolderId,
                });
                if (returnNode?.isLocked) {
                    await returnDocumentService.unlockReturnNode(params);
                    // update global state to reflect change in tabs
                    updateLockedInTabState(tabKey, false);
                } else {
                    await returnDocumentService.lockReturnNode(params);
                    // update global state to reflect change in tabs
                    updateLockedInTabState(tabKey, true);
                }

                await updateTreeData();
            } else {
                const scheduleStatus: ScheduleStatus = {
                    accountId: company?.accountId,
                    companyId: company?.id,
                    taxYear: product?.taxYear,
                    productId: product?.productId,
                    moduleId: Number(
                        getModuleId(product, company, municipalState)
                    ),
                    scheduleId: returnNode?.id,
                    isLocked: !returnNode?.isLocked,
                };

                await scheduleService.updateScheduleLockStatus(scheduleStatus);
                await updateTreeData();
                updateLockedInTabState(tabKey, !returnNode?.isLocked);
            }

            handleSnackbar("Return updated successfully!", "success");
        } catch (error: FixMeLater) {
            handleSnackbar(error?.message as string, "error");
        } finally {
            setIsLockLoading(false);
        }

        handleCloseReturnNodeMenu(e);
    };

    const updateTreeData = async () => {
        const accessToken = await getAccessTokenSilently();

        const treeInput = {
            companyId: dropdownStateView ? Number(folderNode?.id) : company.id,
            productId: product?.productId,
            taxYearId: product?.taxYear,
            moduleId: getModuleId(
                product,
                company ? (dropdownStateView ? folderNode : company) : {},
                municipalState
            ),
        };

        let data: FixMeLater;

        if (dropdownStateView) {
            const compTreeInput = {
                folderId: state?.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
            };

            const { treeCompanies } = await graphqlService.fetchData<{
                treeCompanies: Tree;
            }>(returnCompaniesTreeQuery, { compTreeInput });
            dispatch(
                GlobalStateActions[product?.productName].setTree(treeCompanies)
            );
        } else {
            data = await GQLService.fetchGraphQLData(
                returnTreeQuery,
                { treeInput },
                accessToken
            );
            dispatch(
                GlobalStateActions[product?.productName].setTree(data.tree)
            );
        }
        return data;
    };

    // checks locked returns for the current or future quarters for Municipal product
    const checkLockedNodes = () => {
        return municipalTree?.folderNodes?.some((munifolderNode) => {
            if (Number(munifolderNode?.id) >= Number(folderNode?.id)) {
                return munifolderNode?.returnNodes?.find(
                    (node) => node?.id === returnNode?.id && node?.isLocked
                );
            } else return false;
        });
    };

    const handleDeactivate = async (e) => {
        e.stopPropagation();
        if (checkLockedNodes()) {
            handleSnackbar(
                "Please unlock the returns for the quarter you wish to deactivate, as well as for all following quarters of the current year",
                "error"
            );
        } else setShowDeactivateConfirmation(true);
    };

    const handleDeactivateConfirm = async (e) => {
        e.stopPropagation();
        setDeactivateLoading(true);
        handleConfirmationDialogClose();
        try {
            const accessToken = await getAccessTokenSilently();

            const commonParams: QueryParams = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: product?.taxYear as unknown as string,
                folderId: folderNode?.id,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            };

            const createPayload = (returnNodes: ReturnNode[]) =>
                Array.from(returnNodes).map((returnNode) => {
                    const params: QueryParams = {
                        ...commonParams,
                        returnId: returnNode?.id as string,
                        retalFolderId: returnNode?.retalFolderId as string,
                    };
                    return params;
                });

            // Check if there are any returns that require keeping retal.
            const needToKeepRetalReturn = Array.from(
                folderNode?.returnNodes
            ).some(
                (rN: FixMeLater) =>
                    rN?.id != returnNode?.id &&
                    rN?.retalFolderId == folderNode?.id &&
                    rN?.isActive &&
                    rN?.activateRetal
            );

            let retalReturnsToBeDeactivated: ReturnNode[] = [];

            if (!needToKeepRetalReturn) {
                retalReturnsToBeDeactivated = Array.from(
                    folderNode?.returnNodes
                ).filter(
                    (rN: FixMeLater) =>
                        rN?.id != returnNode?.id &&
                        rN?.isActive &&
                        rN?.retalFolderId != folderNode?.id
                ) as ReturnNode[];
            }

            const deactivationPayloads = createPayload([
                returnNode,
                ...retalReturnsToBeDeactivated,
            ]);
            let deactivationSuccess = true;

            try {
                const deactivationResponse =
                    await returnDocumentService.deactivateReturnNodes(
                        deactivationPayloads
                    );
                dispatch(GlobalStateActions.setToogleFetch());
                //iterate over the response
                deactivationResponse.forEach((responseItem) => {
                    // Find the correct returnNode based on the returnId from the responseItem
                    const returnNodeToDelete = folderNode.returnNodes.find(
                        (it) => it.id == responseItem.returnId
                    );
                    // Check if returnNode was found before generating the key
                    if (returnNodeToDelete) {
                        if (
                            product.productId ==
                            ProductNameToIdMap.get("Municipal")
                        ) {
                            const tabKeyToDelete = generateUniqueKey(
                                responseItem.folderId.toString(),
                                returnNodeToDelete,
                                product,
                                company?.name,
                                municipalState
                            );
                            dispatch(
                                GlobalStateActions.removeTab(tabKeyToDelete)
                            );
                        } else {
                            const tabKeyToDelete = generateUniqueKey(
                                responseItem.folderId.toString(),
                                returnNodeToDelete,
                                product,
                                company?.name
                            );
                            dispatch(
                                GlobalStateActions.removeTab(tabKeyToDelete)
                            );
                        }
                    } else {
                        console.error(
                            `Return node with ID ${responseItem.returnId} not found.`
                        );
                    }
                });
            } catch (error) {
                deactivationSuccess = false;
            }

            const treeInput = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            };

            const data: FixMeLater = await GQLService.fetchGraphQLData(
                returnTreeQuery,
                { treeInput },
                accessToken
            );

            dispatch(
                GlobalStateActions[product?.productName].setTree(data.tree)
            );

            if (deactivationSuccess) {
                handleSnackbar("Returns updated successfully!", "success");
            } else {
                handleSnackbar(
                    "There has been an error updating the return statuses for some returns.",
                    "error"
                );
            }
        } catch (error: FixMeLater) {
            handleSnackbar(error?.message as string, "error");
        } finally {
            setDeactivateLoading(false);
            handleCloseReturnNodeMenu(e);
        }
    };

    const handleConfirmationDialogClose = () => {
        setShowDeactivateConfirmation(false);
    };

    const handleImportModalClose = (e) => {
        e.stopPropagation();
        handleCloseReturnNodeMenu(e);
        setImportModalOpen(false);
    };

    const handleImportSBPModalClose = (e) => {
        e.stopPropagation();
        handleCloseReturnNodeMenu(e);
        setImportSBPModalOpen(false);
    };

    const handleOpenInNewTab = (e) => {
        var queryParams = new URLSearchParams();
        queryParams.append("year", `${product?.taxYear}`);
        if (dropdownStateView) {
            queryParams.append("folderNodeId", state?.id);
            queryParams.append("companyId", folderNode?.id);
        } else {
            queryParams.append("folderNodeId", folderNode?.id);
            queryParams.append("companyId", company?.id);
        }
        queryParams.append("returnNodeId", returnNode?.id);

        window.open(
            `${
                window.location.origin
            }/${getFirstPathSegment()}?${queryParams.toString()}`,
            "_blank"
        );
        handleCloseReturnNodeMenu(e);
    };

    const downloadAsPdf = async (e) => {
        e.stopPropagation();
        try {
            setDownloadAsPdfLoading(true);
            const returnDocId: CreateReturnPDFPayload = {
                returnDocId: {
                    companyId: dropdownStateView ? folderNode?.id : company.id,
                    productId: product.productId,
                    taxYearId: product.taxYear,
                    folderId: dropdownStateView
                        ? Number(state.id)
                        : Number(folderNode?.id),
                    moduleId: Number(
                        getModuleId(
                            product,
                            company
                                ? dropdownStateView
                                    ? folderNode
                                    : company
                                : {},
                            municipalState
                        )
                    ),
                    returnId: returnNode?.id,
                    retalFolderId: returnNode?.retalFolderId,
                },
            };
            const returnsDownloadable =
                await returnPDFService.generateDownloadableAllPdf(returnDocId);
            const { blob, contentDispositionHeader } = returnsDownloadable;
            downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
            handleSnackbar("Downloaded successfully", "success");
        } catch (error) {
            handleSnackbar("Error downloading files", "error");
            console.error("Error downloading files:", error);
        } finally {
            setDownloadAsPdfLoading(false);
            handleCloseReturnNodeMenu(e);
        }
    };

    const handlePaymentRequestExport = (e) => {
        dispatch(
            GlobalStateActions[product?.productName].setPaymentRequestExport({
                ...paymentRequestExport,
                open: true,
            })
        );

        handleCloseReturnNodeMenu(e);
    };

    const handleImportSBP = (e) => {
        e.stopPropagation();
        setImportSBPModalOpen(true);

        handleCloseReturnNodeMenu(e);
    };
    const handleStatusMenuClick = async (event) => {
        event.stopPropagation();
        setStatusMenuAnchorEl(event.currentTarget);
        try {
            const params: QueryParams = {
                companyId: dropdownStateView ? folderNode?.id : company?.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: `${product?.taxYear}`,
                folderId: dropdownStateView
                    ? state?.id.toString()
                    : folderNode?.id.toString(),
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
                returnId: returnNode?.id,
                retalFolderId: returnNode?.retalFolderId,
            };
            const result = await returnStatusService.getReturnStatuses(params);
            setReturnNodeStatus(result);
        } catch (error) {
            console.error("Error fetching statuses:", error);
        }
    };

    const handleStatusMenuClose = (e) => {
        e.stopPropagation();
        setStatusMenuAnchorEl(null);
        handleCloseReturnNodeMenu(e);
    };

    const handleSetStatus = async (statusKey, e) => {
        e.stopPropagation();
        handleStatusMenuClose(e);
        handleCloseReturnNodeMenu(e);
        try {
            const params: QueryParams = {
                companyId: dropdownStateView ? folderNode?.id : company?.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: `${product?.taxYear}`,
                folderId: dropdownStateView ? state?.id : folderNode?.id,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
                returnId: returnNode?.id,
                retalFolderId: returnNode?.retalFolderId,
                returnStatus: statusKey,
            };
            await returnStatusService.setReturnStatuses(params);
            updateReturnStatusInTabState(tabKey, statusKey);
            const treeInput = {
                companyId: company?.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            };
            const accessToken = await getAccessTokenSilently();
            let data: FixMeLater;

            if (dropdownStateView) {
                const compTreeInput = {
                    folderId: state?.id,
                    productId: product?.productId,
                    taxYearId: product?.taxYear,
                };

                const { treeCompanies } = await graphqlService.fetchData<{
                    treeCompanies: Tree;
                }>(returnCompaniesTreeQuery, { compTreeInput });
                dispatch(
                    GlobalStateActions[product?.productName].setTree(
                        treeCompanies
                    )
                );
            } else {
                data = await GQLService.fetchGraphQLData(
                    returnTreeQuery,
                    { treeInput },
                    accessToken
                );
                dispatch(
                    GlobalStateActions[product?.productName].setTree(data.tree)
                );
            }

            handleSnackbar("Return updated successfully!", "success");
            handleSnackbar("Return status have been updated", "success");
        } catch (error) {
            console.error("Error fetching statuses:", error);
            handleSnackbar("Unable to save return status", "error");
        }
    };

    const shouldShowLockOption = (returnNode) => {
        if (returnNode?.displayName === "Actions Report") return false;
        return true;
    };

    return (
        <div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseReturnNodeMenu}
                TransitionProps={{
                    timeout: 0,
                }}
                data-testid="return-node-menu"
            >
                {returnNode?.displayName.includes(
                    "Payment Request Schedule"
                ) && ( // Payment Request Export
                    <MenuItem onClick={(e) => handlePaymentRequestExport(e)}>
                        Export
                    </MenuItem>
                )}

                {shouldShowLockOption(returnNode) && (
                    <MenuItem onClick={(e) => handleLock(e)}>
                        {returnNode?.isLocked ? "Unlock" : "Lock"}
                        {isLockLoading && (
                            <div className="return-node-menu-loading-icon-container">
                                <CircularProgress size={20} />
                            </div>
                        )}
                    </MenuItem>
                )}

                {!returnNode?.isLocked && // Locked returns cannot be deactivated
                    folderNode?.attributes?.displayName !== // For Company Information return nodes, we don't want to show the deactivate option
                        "Company Information" &&
                    folderNode?.attributes?.displayName !==
                        "Summary Schedules" &&
                    folderNode?.id === returnNode?.retalFolderId && // Retals cannot be deactivated
                    folderNode?.attributes?.displayName !==
                        "Municipal Returns Reconciliation" && ( // Municipal Reconciliation Returns cannot be deactivated
                        <MenuItem onClick={(e) => handleDeactivate(e)}>
                            Deactivate
                            {isDeactivateLoading && (
                                <div className="return-node-menu-loading-icon-container">
                                    <CircularProgress size={20} />
                                </div>
                            )}
                        </MenuItem>
                    )}

                <MenuItem onClick={(e) => handleOpenInNewTab(e)}>
                    Open in New Tab
                </MenuItem>

                {returnNode?.displayName.includes("Input Sch Qtr") && ( // For only Input Sch Qtr return nodes
                    <MenuItem onClick={() => setImportModalOpen(true)}>
                        Import Premium Data
                    </MenuItem>
                )}

                {returnNode?.displayName.includes("State Business Page") && ( // For only SBP Sch nodes
                    <MenuItem onClick={(e) => handleImportSBP(e)}>
                        Import SBP Data
                    </MenuItem>
                )}

                {folderNode?.attributes?.displayName !== // For Company Information return nodes, we don't want to show the return status option
                    "Company Information" &&
                    folderNode?.attributes?.displayName !==
                        "Summary Schedules" &&
                    (dropdownStateView
                        ? state?.id.toString() ===
                          returnNode?.retalFolderId.toString()
                        : folderNode?.id === returnNode?.retalFolderId) && ( // Retals doesn't have return status
                        <MenuItem
                            onClick={(e) => handleStatusMenuClick(e)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            Set Status
                            <ChevronRightOutlined />
                        </MenuItem>
                    )}
                {folderNode?.attributes?.displayName !== // For Company Information folder there's no option to download as pdf
                    "Company Information" &&
                    folderNode?.attributes?.displayName !==
                        "Summary Schedules" && (
                        <MenuItem onClick={(e) => downloadAsPdf(e)}>
                            Download As Pdf
                            {downloadAsPdfLoading && (
                                <div className="return-node-menu-loading-icon-container">
                                    <CircularProgress size={20} />
                                </div>
                            )}
                        </MenuItem>
                    )}
            </Menu>
            <Menu
                anchorEl={statusMenuAnchorEl}
                open={Boolean(statusMenuAnchorEl)}
                onClose={(e) => handleStatusMenuClose(e)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {Object.entries(returnNodeStatus).map(([statusKey, status]) => (
                    <MenuItem
                        onClick={(e) => handleSetStatus(statusKey, e)}
                        key={statusKey}
                    >
                        <ListItemIcon>
                            {returnNode?.returnStatus ===
                            parseInt(statusKey, 10) ? (
                                <RadioButtonCheckedOutlined />
                            ) : (
                                <RadioButtonUncheckedOutlined />
                            )}
                        </ListItemIcon>
                        {status}
                    </MenuItem>
                ))}
            </Menu>

            <Modal
                open={importModalOpen}
                onClose={() => setImportModalOpen(false)}
                title={`Import Premium Data for ${returnNode?.displayName}`}
            >
                <ImportPremiumData
                    scheduleInput={{
                        companyId: dropdownStateView
                            ? folderNode?.id?.toString()
                            : company?.id?.toString(),
                        folderId: folderNode?.id,
                        moduleId: getModuleId(
                            product,
                            company
                                ? dropdownStateView
                                    ? folderNode
                                    : company
                                : {},
                            municipalState
                        ),
                        pageNumber: "0",
                        productId: product?.productId.toString(),
                        returnId: returnNode?.id,
                        taxYearId: product?.taxYear.toString(),
                    }}
                    onClose={(e) => handleImportModalClose(e)}
                    handleSnackbar={handleSnackbar}
                />
            </Modal>

            <Modal
                open={importSBPModalOpen}
                onClose={() => setImportSBPModalOpen(false)}
                title={`Import SBP Data for ${returnNode?.displayName}`}
            >
                <ImportSBPData
                    scheduleInput={{
                        companyId: dropdownStateView
                            ? folderNode?.id?.toString()
                            : company?.id?.toString(),
                        folderId: folderNode?.id,
                        moduleId: getModuleId(
                            product,
                            company
                                ? dropdownStateView
                                    ? folderNode
                                    : company
                                : {},
                            municipalState
                        ),
                        pageNumber: "0",
                        productId: product?.productId.toString(),
                        returnId: returnNode?.id,
                        taxYearId: product?.taxYear.toString(),
                    }}
                    onClose={(e) => handleImportSBPModalClose(e)}
                    handleSnackbar={handleSnackbar}
                />
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                autoHideDuration={snackbarDuration}
            />

            <Dialog
                open={showDeactivateConfirmation}
                onClose={handleConfirmationDialogClose}
            >
                <DialogTitle>
                    {product?.productName === "Municipal"
                        ? `${returnNode?.displayName} Deactivation`
                        : "Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {product?.productName === "Municipal"
                            ? "Warning: All user input and calculated data for all quarterly returns for this municipality will be deleted."
                            : `Are you sure you want to deactivate${" "}
                            ${
                                returnNode?.displayName
                            }? This will permanently delete
                            all its data.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledFlexContainer justify={"center"}>
                        <Button
                            onClick={handleConfirmationDialogClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDeactivateConfirm}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </StyledFlexContainer>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReturnNodeMenu;
