import React, { useContext } from "react";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsContextState,
} from "../EfileNaicOptins.context";
import {
    ActionsContainer,
    CompanyTypeSelectorContainer,
    CompanyTypeStyled,
    FilingStatusContainer,
} from "./CompanyType.styled";
import {
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent,
    Grid,
    Chip,
    Button,
    Divider,
    Alert,
} from "@mui/material";
import Loader from "src/components/Loader/Loader";
import { Delete } from "@mui/icons-material";

export const CompanyType: React.FC = () => {
    const EfileNaicOptinsCtx: EfileNaicOptinsContextState | undefined =
        useContext(EfileNaicOptinsContext);

    const handleSelectChange = (event: SelectChangeEvent<string>): void => {
        const newCompanyType = EfileNaicOptinsCtx?.formData.companyTypes.find(
            (companyType) => companyType.identifier === event.target.value
        );
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            selectedCompanyType: newCompanyType,
        });
    };

    return (
        <CompanyTypeStyled>
            <CompanyTypeSelectorContainer component={"fieldset"}>
                <legend>
                    <strong>Select company type</strong>
                </legend>
                <FormControl fullWidth>
                    <span
                        style={{
                            fontStyle: "italic",
                            marginBottom: "10px",
                        }}
                    >
                        Please verify that your company type is correct
                    </span>
                    <Select
                        label="Company Type"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                            EfileNaicOptinsCtx?.formData.selectedCompanyType
                                ?.identifier
                        }
                        onChange={handleSelectChange}
                        MenuProps={{
                            disablePortal: true,
                        }}
                    >
                        {EfileNaicOptinsCtx?.formData.companyTypes.map(
                            (companyType) => {
                                return (
                                    <MenuItem
                                        value={companyType.identifier}
                                        key={companyType.identifier}
                                    >
                                        {companyType.name}
                                    </MenuItem>
                                );
                            }
                        )}
                    </Select>
                </FormControl>
            </CompanyTypeSelectorContainer>
            <FilingStatusContainer component={"fieldset"}>
                <legend>
                    <strong>Status</strong>
                </legend>
                {EfileNaicOptinsCtx?.fetchingFilingStatus && <Loader />}
                {!EfileNaicOptinsCtx?.fetchingFilingStatus &&
                    EfileNaicOptinsCtx?.formData.filingStatus && (
                        <>
                            <Grid container spacing={1} alignItems="center">
                                {EfileNaicOptinsCtx.formData.filingStatus
                                    ?.filingStatus?.filingStatus && (
                                    <>
                                        <Grid item>Status:</Grid>
                                        <Grid item color="success">
                                            <Chip
                                                label={
                                                    EfileNaicOptinsCtx.formData
                                                        .filingStatus
                                                        ?.filingStatus
                                                        ?.filingStatus
                                                }
                                                color="info"
                                            ></Chip>
                                        </Grid>
                                    </>
                                )}
                                {EfileNaicOptinsCtx.formData.filingStatus
                                    ?.clientError && (
                                    <Grid item>
                                        <Alert severity="error">
                                            {
                                                EfileNaicOptinsCtx.formData
                                                    .filingStatus?.clientError
                                                    .message
                                            }
                                        </Alert>
                                    </Grid>
                                )}
                            </Grid>
                            <Divider variant="fullWidth">
                                <strong>Actions</strong>
                            </Divider>
                            <ActionsContainer>
                                <Button
                                    endIcon={<Delete />}
                                    variant="contained"
                                    color="error"
                                >
                                    Delete
                                </Button>
                            </ActionsContainer>
                        </>
                    )}
            </FilingStatusContainer>
        </CompanyTypeStyled>
    );
};
