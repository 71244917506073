import {
    ExportPaymentRequestDownloadable,
    MimeType,
    ReturnDocumentId,
    ScheduleRows,
    ScheduleStatus,
} from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { StateCheckStatus } from "../types/StatesChecked.types";

export class ScheduleService {
    private static _instance: ScheduleService;
    private readonly BASE_PATH: string = "/api/schedule";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public static getInstance(): ScheduleService {
        if (!ScheduleService._instance) {
            ScheduleService._instance = new ScheduleService();
        }
        return ScheduleService._instance;
    }

    rowsUpdate(schedule: ScheduleRows): Promise<ScheduleRows> {
        return this.api.post<ScheduleRows>(
            this.getPath("/update/rows"),
            schedule
        );
    }

    async getExportPaymentRequestCSV(
        payload: FormData
    ): Promise<ExportPaymentRequestDownloadable> {
        const response = await this.api.post(
            this.getPath("/export/payment-request-schedule"),
            payload,
            {
                headers: {
                    Accept: MimeType.APP_TEXT,
                    "Content-Type": MimeType.MULTIPART_FORM_DATA,
                },
            },
            true
        );

        const contentDispositionHeader =
            response.headers.get("Content-Disposition") ?? "";

        const responseBlob = await response.blob();
        const csvBlob = {
            csvFile: new Blob([responseBlob], {
                type: MimeType.CSV,
            }),
            contentDispositionHeader,
        };
        return csvBlob;
    }

    initializeMunicipalSchedules(
        data: Partial<ReturnDocumentId>
    ): Promise<void> {
        return this.api.post<void>(this.getPath("/muni-init"), data);
    }

    uploadPremiumData(
        data: FormData,
        option: string,
        importId: string
    ): Promise<string[]> {
        return this.api.post<string[]>(
            this.getPath(`/import?option=${option}&importId=${importId}`),
            data,
            {
                headers: {
                    "Content-Type": MimeType.MULTIPART_FORM_DATA,
                },
            }
        );
    }

    uploadSBPData(
        data: FormData,
        option: string,
        stateCheckStatus: StateCheckStatus[],
        format: string
    ): Promise<string[]> {
        const selectedStates = stateCheckStatus
            .filter((st) => st.checked)
            .map((s) => s.value.abbrev);
        const selectedStatesString = selectedStates.join(",");
        return this.api.post<string[]>(
            this.getPath(
                `/import-sbp?option=${option}&jurisdictions=${selectedStatesString}&format=${format}`
            ),
            data,
            {
                headers: {
                    "Content-Type": MimeType.MULTIPART_FORM_DATA,
                },
            }
        );
    }

    updateScheduleLockStatus(scheduleStatus: ScheduleStatus) {
        return this.api.post<ScheduleStatus>(
            this.getPath(`/schedule-status`),
            scheduleStatus
        );
    }
}
