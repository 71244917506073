import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
    Checkbox,
    IconButton,
    InputBase,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import {
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridSortDirection,
    GridSortItem,
    gridNumberComparator,
} from "@mui/x-data-grid";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import {
    addressScheduleId,
    scheduleSBPId,
    scheduleSBPIdLifePage1,
    scheduleSBPIdLifePage2,
} from "src/constants/Schedules";
import { EncryptionService } from "src/services/EncryptionService";
import {
    CellAlignment,
    CellType,
    ColumnTemplateDTO,
    DataGridRow,
    OverrideState,
    ReturnNode,
    RowDTO,
} from "src/types";
import Formatter from "src/utils/Formatter";
import { selectColumnCells } from "./CopyAndPaste.util";
import {
    StyledCustomColumnHeader,
    StyledCustomColumnHeaderIcons,
} from "../DataTable.styled";

export const WrappedGridEditDateInput = (props): React.JSX.Element => {
    const { InputProps, focused, ...other } = props;
    return <InputBase fullWidth {...InputProps} {...other} />;
};

export const onSortIconClicked = (
    params: GridColumnHeaderParams,
    sortModel: GridSortItem[],
    setSortModel: (value: React.SetStateAction<GridSortItem[]>) => void
) => {
    const currentSortValue: GridSortDirection = sortModel.find(
        (sort) => sort.field === params.colDef.field
    )?.sort;
    setSortModel(
        currentSortValue === "desc"
            ? []
            : [
                  {
                      field: params.colDef.field,
                      sort: currentSortValue === "asc" ? "desc" : "asc",
                  },
              ]
    );
};

export const getSortIcon = (field: string, sortModel: GridSortItem[]) => {
    const currentSortValue: GridSortDirection = sortModel.find(
        (sort) => sort.field === field
    )?.sort;
    return currentSortValue === "asc" ? (
        <ArrowUpwardIcon sx={{ fontSize: "16px" }} />
    ) : currentSortValue === "desc" ? (
        <ArrowDownwardIcon sx={{ fontSize: "16px" }} />
    ) : (
        <ArrowUpwardIcon sx={{ fontSize: "16px", fill: "#c8c8c8" }} />
    );
};

export const renderCell = (
    params: GridRenderCellParams,
    column: ColumnTemplateDTO,
    onDropdownChange: (e: SelectChangeEvent, rowId: number) => void,
    onCheckboxChange: (rowId: number, columnName: string) => void
): React.ReactNode => {
    if (params.rowNode.type === "group") {
        return params.value;
    }
    const cellIdentifier = `${params.row.id}_${column.columnTemplate.name}`;
    const options = params.row[`${cellIdentifier}_options`];
    const currentIndex = params.row[`${cellIdentifier}_currentIndex`];
    const currentValue = params.row[`${column.columnTemplate.name}`];
    const format: string = params.row[`${cellIdentifier}_format`];
    const isEditable = params.row[`${cellIdentifier}_editable`];
    const formatType: string = params.row[`${cellIdentifier}_formatType`];
    const decimalPrecision = params.row[`${cellIdentifier}_decimalPrecision`];
    const hasPendingChanges = params.row["hasPendingChanges"];

    switch (params.row[`${cellIdentifier}_cellType`]) {
        case "ADDRESS_SELECT":
        case "DROPDOWN":
            return options?.length > 1 ? (
                <Select
                    className="data-table-select"
                    key={`${cellIdentifier}_select`}
                    value={currentIndex}
                    onChange={(e) => onDropdownChange(e, params.row.id)}
                    autoWidth
                >
                    {options.map((option) => (
                        <MenuItem
                            key={`${cellIdentifier}_${option.value}_select`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                currentValue
            );
        case "CHECK":
            return (
                <Checkbox
                    checked={currentValue === "true"}
                    onChange={(e) =>
                        onCheckboxChange(
                            params.row.id,
                            column.columnTemplate.name
                        )
                    }
                />
            );
        case "TEXT":
            if (column.columnTemplate.name === "Contact Email") {
                return <a href={`mailto:${currentValue}`}>{currentValue}</a>;
            }
            if (column.columnTemplate.name === "Web Address") {
                return (
                    <a href={currentValue} target="_blank" rel="noreferrer">
                        {currentValue}
                    </a>
                );
            }
            return currentValue;
        case "PASSWORD":
            if (currentValue) {
                return (
                    <PasswordCell
                        hasPendingChanges={hasPendingChanges}
                        encryptedPassword={currentValue}
                        isEditable={isEditable}
                    />
                );
            } else return currentValue;
        case "DATE":
            return currentValue
                ? dayjs(currentValue).format(format.toLocaleUpperCase())
                : "";
        case "NUMBER": {
            const formatArray = format.split(";");
            if (formatArray.length === 3 && currentValue) {
                return Formatter.format(
                    {
                        fieldType: "NumberFieldTemplate",
                        numericFormatType: formatType,
                        format: formatArray,
                        decimalPrecision,
                        excludedChar: [""],
                    },
                    parseFloat(currentValue)
                );
            }
            return currentValue;
        }
        default:
            return currentValue;
    }
};

export const renderEditCell = (
    params: GridRenderCellParams,
    column: ColumnTemplateDTO,
    onDateChange: (
        newValue: Dayjs | null,
        rowId: number,
        columnName: string
    ) => void
) => {
    const cellIdentifier = `${params.row.id}_${column.columnTemplate.name}`;
    const currentValue = params.row[`${column.columnTemplate.name}`];
    const format: string = params.row[`${cellIdentifier}_format`];
    switch (params.row[`${cellIdentifier}_cellType`]) {
        case "DATE":
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={dayjs(currentValue)}
                        autoFocus
                        format={format.toUpperCase()}
                        onChange={(newValue) =>
                            onDateChange(
                                newValue,
                                params.row.id,
                                column.columnTemplate.name
                            )
                        }
                        slots={{ textField: WrappedGridEditDateInput }}
                    />
                </LocalizationProvider>
            );
    }
};

export const customColumnHeader = (
    headerParams: GridColumnHeaderParams<any, any, any>,
    isGroup: boolean = false,
    apiRef: React.MutableRefObject<GridApiPremium>,
    sortModel: GridSortItem[],
    setSortModel: (value: React.SetStateAction<GridSortItem[]>) => void
) => {
    return (
        <>
            <StyledCustomColumnHeader
                onClick={() =>
                    selectColumnCells(apiRef, headerParams.colDef.field)
                }
            >
                {isGroup ? (
                    <span>Group By ({headerParams.colDef.headerName})</span>
                ) : (
                    <span>{headerParams.colDef.headerName}</span>
                )}
            </StyledCustomColumnHeader>
            <StyledCustomColumnHeaderIcons>
                <IconButton
                    size="small"
                    onClick={(event) => {
                        event.stopPropagation();
                        onSortIconClicked(
                            headerParams,
                            sortModel,
                            setSortModel
                        );
                    }}
                >
                    {getSortIcon(headerParams.colDef.field, sortModel)}
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() =>
                        apiRef.current.showColumnMenu(headerParams.field)
                    }
                >
                    <MoreVertIcon sx={{ fontSize: "16px" }} />
                </IconButton>
            </StyledCustomColumnHeaderIcons>
        </>
    );
};

export const createDataGridColumns = (
    columnTemplateDTOs: ColumnTemplateDTO[],
    rowDTOs: RowDTO[],
    renderCell: (
        params: GridRenderCellParams,
        column: ColumnTemplateDTO,
        onDropdownChange: (e: SelectChangeEvent, rowId: number) => void,
        onCheckboxChange: (rowId: number, columnName: string) => void
    ) => React.ReactNode,
    onDropdownChange: (e: SelectChangeEvent, rowId: number) => void,
    onCheckboxChange: (rowId: number, columnName: string) => void,
    onDateChange: (
        newValue: Dayjs | null,
        rowId: number,
        columnName: string
    ) => void,
    customColumnHeader: (
        headerParams: GridColumnHeaderParams<any, any, any>,
        isGroup: boolean,
        apiRef: React.MutableRefObject<GridApiPremium>,
        sortModel: GridSortItem[],
        setSortModel: (value: React.SetStateAction<GridSortItem[]>) => void
    ) => React.ReactNode,
    apiRef: React.MutableRefObject<GridApiPremium>,
    columnMap: Map<string, number>,
    sortModel: GridSortItem[],
    setSortModel: (value: React.SetStateAction<GridSortItem[]>) => void
): GridColDef[] => {
    return columnTemplateDTOs.map((column: ColumnTemplateDTO, index) => {
        const gridColumn: GridColDef = {
            field: column.columnTemplate.name,
            headerName: column.columnTemplate.name,
            width: column.columnTemplate.width + 40,
            editable: true,
            aggregable: false,
            renderCell: (param) =>
                renderCell(param, column, onDropdownChange, onCheckboxChange),
            cellClassName: (params) => {
                const cellIdentifier = `${params.row.id}_${column.columnTemplate.name}`;

                const overrideState =
                    params.row[`${cellIdentifier}_overrideState`];
                const overrided =
                    overrideState !== OverrideState.OVERRIDE_NEVER &&
                    overrideState !== OverrideState.OVERRIDE_USER_CHOICE
                        ? params.row[`${cellIdentifier}_overrided`]
                        : false;

                const cellType = params.row[`${cellIdentifier}_cellType`];
                let cellClassName =
                    index === 0
                        ? "first-column-cell"
                        : overrided
                        ? "overrided-column-cell"
                        : "";

                cellClassName += cellType === "LABEL" ? " label-class" : "";
                cellClassName +=
                    column.columnTemplate.alignment === CellAlignment.RIGHT
                        ? " align-right"
                        : "";
                cellClassName +=
                    column.columnTemplate.alignment === CellAlignment.CENTER
                        ? " align-center"
                        : "";
                cellClassName +=
                    column.columnTemplate.alignment === CellAlignment.LEFT
                        ? " align-left"
                        : "";

                return cellClassName;
            },
            sortable: false,
            renderHeader(params) {
                return customColumnHeader(
                    params,
                    false,
                    apiRef,
                    sortModel,
                    setSortModel
                );
            },
        };
        const selectedColumnIndex = columnMap.get(column.columnTemplate.name);
        const cellType =
            rowDTOs[0]?.cellGroups[0]?.cells[selectedColumnIndex!!]?.cellType;

        if (cellType === CellType.DATE) {
            gridColumn.renderEditCell = (param) => {
                return renderEditCell(param, column, onDateChange);
            };
        }

        if (column.columnTemplate.name === "Municipality") {
            return {
                ...gridColumn,
                sortComparator: (value1, value2) => {
                    if (
                        value1 === "OTHER MUNICIPALITIES" ||
                        value2 === "OTHER MUNICIPALITIES" ||
                        value1 === "TOTAL MUNICIPALITIES" ||
                        value2 === "TOTAL MUNICIPALITIES"
                    ) {
                        return 0;
                    } else return `${value1}`.localeCompare(value2);
                },
            };
        }

        if (
            column.columnTemplate.name === "Code" ||
            cellType === CellType.NUMBER
        ) {
            return {
                ...gridColumn,
                sortComparator: gridNumberComparator,
            };
        }

        return gridColumn;
    });
};

export const createDataGridRows = (
    columnTemplateDTOs: ColumnTemplateDTO[],
    rowDTOs: RowDTO[],
    returnNode: ReturnNode
): DataGridRow[] => {
    if (!rowDTOs?.length) {
        return [];
    }
    const canUseDisplayOrder = checkDisplayOrderInRowDTOs(rowDTOs);
    return rowDTOs.map((rowDTO) => {
        const row: DataGridRow = {
            id: canUseDisplayOrder ? rowDTO.displayOrder : rowDTO.rowId,
        };
        rowDTO.cellGroups[
            returnNode.id !== addressScheduleId ? 0 : rowDTO.currentIndex
        ].cells.forEach((cell, index) => {
            const columnTemplate = columnTemplateDTOs[index].columnTemplate;
            const cellIdentifier = `${row.id}_${columnTemplate.name}`;
            const overrided = cell.ov !== undefined && cell.ov !== "";
            const currentValue = overrided ? cell.ov : cell.v;
            const overrideState = cell.overrideState;

            row[`${cellIdentifier}_format`] = columnTemplate.format;
            row[`${cellIdentifier}_alignment`] = cell.alignment;
            switch (columnTemplate.cellType) {
                case "ADDRESS_SELECT":
                case "DROPDOWN":
                    row[`${cellIdentifier}_currentIndex`] = rowDTO.currentIndex;
                    row[`${cellIdentifier}_options`] = rowDTO.cellGroups.map(
                        (group, cellIndex) => {
                            return {
                                value: cellIndex,
                                label: group.cells[index].v,
                            };
                        }
                    );
                    row[`${cellIdentifier}_cellType`] = columnTemplate.cellType;
                    break;
                default:
                    row[`${cellIdentifier}_cellType`] = cell.cellType;
                    break;
            }
            if (cell.cellType === "DATE") {
                row[`${cellIdentifier}_format`] = cell.format;
            }
            row[columnTemplate.name] = currentValue;
            row[`${cellIdentifier}_editable`] = cell.editable;
            row[`${cellIdentifier}_overrided`] = overrided;
            row[`${cellIdentifier}_overrideState`] = overrideState;
            row[`${cellIdentifier}_formatType`] = columnTemplate.formatType;
            row[`${cellIdentifier}_decimalPrecision`] =
                columnTemplate.decimalPrecision;
            row["hasPendingChanges"] = cell["hasPendingChanges"];
        });

        return row;
    });
};

export const isSBPSchedule = (returnNode: ReturnNode) =>
    returnNode.id === scheduleSBPIdLifePage1 ||
    returnNode.id === scheduleSBPIdLifePage2 ||
    returnNode.id === scheduleSBPId;

export const isTwoPageSBPSchedule = (returnNode: ReturnNode) =>
    returnNode.id === scheduleSBPIdLifePage1 ||
    returnNode.id === scheduleSBPIdLifePage2;

export const isCellEditable = (params: GridCellParams): boolean => {
    const columnName = params.colDef.field;
    const editable = params.row[`${params.row.id}_${columnName}_editable`];
    switch (params.row[`${columnName}_cellType`]) {
        case "ADDRESS_SELECT":
        case "DROPDOWN":
        case "CHECK":
            return false;
        default:
            return Boolean(editable);
    }
};

/**
 * Checks if every RowDTO in the provided array has a displayOrder that is a number,
 * greater than or equal to 0, and not null.
 *
 * @param {RowDTO[]} rowDTOs - The array of RowDTO objects to check.
 * @returns {boolean} - Returns true if all RowDTOs have a valid displayOrder, otherwise false.
 */
export const checkDisplayOrderInRowDTOs = (rowDTOs: RowDTO[]): boolean => {
    return rowDTOs.every((rowDTO) => {
        const value = rowDTO.displayOrder;
        return typeof value === "number" && value >= 0 && value !== null;
    });
};

const PasswordCell: React.FC<{
    encryptedPassword: string;
    isEditable: boolean;
    hasPendingChanges: boolean;
}> = ({ encryptedPassword, isEditable, hasPendingChanges }) => {
    const encryptionService = EncryptionService.getInstance();
    const canDecrypt: boolean = isEditable;
    const [showPassword, setShowPassword] = useState(false);
    const [decryptedPassword, setDecryptedPassword] =
        useState<string>("************");

    const handleRevealPassword = async (event: React.MouseEvent) => {
        event.stopPropagation();
        if (canDecrypt && !showPassword) {
            try {
                const data = await encryptionService.decrypt({
                    text: encryptedPassword,
                });
                const decryptedText = data.decryptedText;
                setDecryptedPassword(decryptedText);
            } catch (error) {
                console.error("Error decrypting password:", error);
                setDecryptedPassword("Error decrypting");
            }
        }
        setShowPassword(!showPassword);
    };

    return (
        <span
            style={{
                position: "relative",
                display: "inline-block",
                width: "100%",
            }}
        >
            <span>{showPassword ? decryptedPassword : "************"}</span>
            {canDecrypt && !hasPendingChanges && (
                <IconButton
                    onClick={handleRevealPassword}
                    size="small"
                    aria-label={
                        showPassword ? "Hide password" : "Show password"
                    }
                    style={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                    }}
                >
                    {showPassword ? (
                        <VisibilityOffIcon fontSize="small" />
                    ) : (
                        <VisibilityIcon fontSize="small" />
                    )}
                </IconButton>
            )}
        </span>
    );
};

export default PasswordCell;

export const findUpdatedColumnNames = (
    newRow: DataGridRow,
    oldRow: DataGridRow
): string[] => {
    const updatedKeys: string[] = [];
    for (const key in newRow) {
        if (newRow[key] !== oldRow[key]) {
            updatedKeys.push(key);
        }
    }
    return updatedKeys;
};
