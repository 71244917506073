import React, {
    useState,
    CSSProperties,
    useEffect,
    forwardRef,
    PropsWithChildren,
} from "react";
import StyledLayoutPane, {
    StyledLayoutPaneContent,
    StyledLayoutPaneHandle,
    StyledLayoutPaneHeader,
    StyledLayoutPaneToggle,
} from "./LayoutPane.styled";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import {
    KeyboardDoubleArrowDown,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowUp,
    KeyboardDoubleArrowRight,
    PushPin,
} from "@mui/icons-material";

export interface LayoutPaneProps {
    initialSize: number;
    maxSize: number;
    minSize: number;
    position: "left" | "right" | "top" | "bottom";
    title?: string;
    zIndex?: number;
    expanded?: boolean;
    pinned?: boolean;
    displayToggleButton?: boolean;
    closeOnClickAway?: boolean;
    onClickAwayCallback?: () => void;
    onPinnedChange?: (pinned: boolean) => void;
    onExpandedChange?: (expanded: boolean) => void;
}

export const LayoutPane = forwardRef<
    HTMLDivElement,
    PropsWithChildren<LayoutPaneProps>
>(
    (
        {
            children,
            closeOnClickAway = false,
            initialSize,
            maxSize,
            minSize,
            position,
            title,
            zIndex,
            pinned: externalPinned,
            expanded: externalExpanded,
            displayToggleButton = true,
            onClickAwayCallback,
            onPinnedChange,
            onExpandedChange,
        },
        ref
    ) => {
        const getInitialSize = (
            initialSize: number,
            minSize: number,
            maxSize: number
        ) => {
            if (initialSize !== undefined) {
                if (minSize !== undefined && initialSize < minSize) {
                    return minSize;
                }
                if (maxSize !== undefined && initialSize > maxSize) {
                    return maxSize;
                }
                return initialSize;
            }
            return minSize !== undefined ? minSize : 100;
        };

        const HANDLE_SIZE = 5;

        // ===== Local state =====
        const [size, setSize] = useState(
            getInitialSize(initialSize, minSize, maxSize)
        ); // Initial size
        const [pinned, setPinned] = useState(externalPinned ?? false);
        const [expanded, setExpanded] = useState<boolean>(
            externalExpanded ?? false
        );

        // ===== Constants & Variables =====

        // ===== Effects =====

        useEffect(() => {
            if (onExpandedChange) {
                onExpandedChange(expanded);
            }
        }, [expanded]);

        useEffect(() => {
            if (externalExpanded !== undefined) {
                setExpanded(externalExpanded);
            }
        }, [externalExpanded]);

        useEffect(() => {
            if (!pinned) {
                setExpanded(false);
            }
            if (onPinnedChange) {
                onPinnedChange(pinned);
            }
        }, [pinned]);

        useEffect(() => {
            if (externalPinned !== undefined) {
                setPinned(externalPinned);
            }
        }, [externalPinned]);

        useEffect(() => {
            if (initialSize !== undefined) {
                setSize(initialSize);
            }
            setSize(getInitialSize(initialSize, minSize, maxSize));
        }, [initialSize]);

        // ===== Functions =====
        const handleMouseDown = (e: React.MouseEvent) => {
            const startSize = size;
            const startPosition =
                position === "left" || position === "right"
                    ? e.clientX
                    : e.clientY;

            const handleMouseMove = (e: MouseEvent) => {
                const currentPosition =
                    position === "left" || position === "right"
                        ? e.clientX
                        : e.clientY;
                const delta = currentPosition - startPosition;
                let newSize =
                    startSize +
                    (position === "left" || position === "top"
                        ? delta
                        : -delta);

                // Restrict the size within MIN_SIZE and MAX_SIZE
                if (newSize < minSize) {
                    newSize = minSize;
                } else if (newSize > maxSize) {
                    newSize = maxSize;
                }

                setSize(newSize);
            };

            const handleMouseUp = () => {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
            };

            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        };

        const handleClickAway = () => {
            if (!pinned && expanded && closeOnClickAway) {
                setExpanded(false);
            }
            if (onClickAwayCallback) {
                onClickAwayCallback();
            }
        };

        const getToggleIcon = (position, expanded) => {
            const sx: CSSProperties = {
                width: "16px",
                height: "16px",
            };
            switch (position) {
                case "left":
                    return expanded ? (
                        <KeyboardDoubleArrowLeft sx={sx} />
                    ) : (
                        <KeyboardDoubleArrowRight sx={sx} />
                    );
                case "right":
                    return expanded ? (
                        <KeyboardDoubleArrowRight sx={sx} />
                    ) : (
                        <KeyboardDoubleArrowLeft sx={sx} />
                    );
                case "top":
                    return expanded ? (
                        <KeyboardDoubleArrowUp sx={sx} />
                    ) : (
                        <KeyboardDoubleArrowDown sx={sx} />
                    );
                case "bottom":
                    return expanded ? (
                        <KeyboardDoubleArrowDown sx={sx} />
                    ) : (
                        <KeyboardDoubleArrowUp sx={sx} />
                    );
            }
        };

        const getTooltipPosition = (
            position: "left" | "right" | "top" | "bottom"
        ) => {
            switch (position) {
                case "left":
                    return "right";
                case "right":
                    return "left";
                case "top":
                    return "bottom";
                case "bottom":
                    return "top";
            }
        };

        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <StyledLayoutPane
                    ref={ref}
                    $position={position}
                    $pinned={pinned}
                    $expanded={expanded}
                    $size={size}
                    $zindex={zIndex}
                >
                    <StyledLayoutPaneHeader>
                        <span>{title}</span>
                        <Tooltip
                            title={pinned ? "Unpin" : "Pin"}
                            placement={getTooltipPosition(position)}
                        >
                            <IconButton
                                onClick={() => {
                                    setPinned(!pinned);
                                }}
                            >
                                <PushPin
                                    sx={{
                                        fill: "white",
                                        transform: pinned
                                            ? "rotate(45deg)"
                                            : "rotate(0deg)",
                                        width: "16px",
                                        height: "16px",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </StyledLayoutPaneHeader>
                    <StyledLayoutPaneContent>
                        {children}
                    </StyledLayoutPaneContent>
                    <StyledLayoutPaneHandle
                        $position={position}
                        onMouseDown={handleMouseDown}
                        $size={HANDLE_SIZE}
                        $pinned={pinned}
                    >
                        {displayToggleButton && !pinned && (
                            // Toggle button will only be shown if the pane is not pinned
                            <Tooltip
                                title={expanded ? "Collapse" : "Expand"}
                                placement={getTooltipPosition(position)}
                            >
                                <StyledLayoutPaneToggle
                                    $position={position}
                                    $size={20}
                                    $handleSize={HANDLE_SIZE}
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}
                                >
                                    {getToggleIcon(position, expanded)}
                                </StyledLayoutPaneToggle>
                            </Tooltip>
                        )}
                    </StyledLayoutPaneHandle>
                </StyledLayoutPane>
            </ClickAwayListener>
        );
    }
);

LayoutPane.displayName = "LayoutPane";
