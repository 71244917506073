import styled from "styled-components";
import { Tabs } from "@mui/material";

export interface StyledLayoutPaneTabsProps {
    $isSelected?: boolean;
}

export const StyledLayoutPaneTabs = styled(
    Tabs
).attrs<StyledLayoutPaneTabsProps>((props) => ({
    $isSelected: props.$isSelected,
}))`
    background-color: ${({ theme }) => theme.palette.primary};

    & .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
    }

    & .MuiTabs-indicatorSpan {
        max-width: 40px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.white};
    }

    & .MuiTabScrollButton-root{
        color: ${({ theme }) => theme.colors.white};
        &.Mui-disabled {
            opacity: 0.2;
        }
    }
`;

