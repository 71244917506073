import { Grid } from "@mui/material";
import React, { FC } from "react";
import "./Overview.scss";
import AccountContact from "./components/AccountContact/AccountContact";
import AccountCsr from "./components/AccountCsr/AccountCsr";
import AccountInformation from "./components/AccountInformation/AccountInformation";
import Products from "./components/Products/Products";
import SubAccounts from "./components/SubAccounts/SubAccounts";
import MigrationWidget from "src/components/Migration/MigrationWidget";

const Overview: FC = () => {
    return (
        <div className="overview-container">
            <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={6} lg={4}>
                    <AccountInformation />
                </Grid>

                <Grid item xs={6} lg={4}>
                    <AccountContact />
                </Grid>

                <Grid item xs={6} lg={4}>
                    <AccountCsr />
                </Grid>

                {/* Row 2 */}

                <Grid item xs={6} lg={4}>
                    <SubAccounts />
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Products />
                </Grid>

                {/* Row 3 */}
                <Grid item xs={12} lg={6}>
                    <MigrationWidget />
                </Grid>
            </Grid>
        </div>
    );
};

export default Overview;
