import React, { useContext } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsContextState,
    PAYMENT_TYPE,
} from "../EfileNaicOptins.context";
import {
    PaymentInfoElectronicPaymentInformation,
    PaymentInfoInstructions,
    PaymentInfoSelectorContainer,
    PaymentInfoStyled,
} from "./PaymentInfo.styled";
import { Payment } from "@mui/icons-material";

export const PaymentInfo: React.FC = () => {
    const EfileNaicOptinsCtx: EfileNaicOptinsContextState | undefined =
        useContext(EfileNaicOptinsContext);

    const handleSelectChange = (event: SelectChangeEvent<PAYMENT_TYPE>): void => {
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            paymentType: event.target.value as PAYMENT_TYPE,
        });
    }

    return (
        <PaymentInfoStyled>
            <PaymentInfoSelectorContainer component={"fieldset"}>
                <legend>
                    <strong>Select payment type</strong>
                </legend>
                <FormControl fullWidth >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EfileNaicOptinsCtx?.formData.paymentType}
                        onChange={handleSelectChange}
                        MenuProps={{
                            disablePortal: true,
                        }}
                    >
                        <MenuItem value={PAYMENT_TYPE.DEBIT}>{PAYMENT_TYPE.DEBIT}</MenuItem>
                        <MenuItem value={PAYMENT_TYPE.CREDIT}>{PAYMENT_TYPE.CREDIT}</MenuItem>
                    </Select>
                </FormControl>
            </PaymentInfoSelectorContainer>
            <PaymentInfoElectronicPaymentInformation component={"fieldset"}>
                <legend>
                    <strong>Electronic Payment Information</strong>
                </legend>
                <span>
                    <strong>Payment Breakdown of Taxes and Fees Owed</strong>
                </span>
                <span>
                    NAIC OPTins Transaction Fee: <strong>$17.50</strong>
                </span>
                <span>
                    Total: <strong>$17.50</strong>
                </span>
            </PaymentInfoElectronicPaymentInformation>
            <PaymentInfoInstructions component={"fieldset"}>
                <legend>
                    <strong>Instructions</strong>
                </legend>
                <p>
                    When paying by credit, upon succsessful submission you will
                    receive and addenda which you will give to your bank to
                    submit the payment.
                </p>
                <p>
                    <strong>
                        The filling is not considered submitted until the
                        payment is processed.
                    </strong>{" "}
                    You will receive and acceptance email within 1 business day
                    from when the payment is processed by OPTins.
                </p>
            </PaymentInfoInstructions>
        </PaymentInfoStyled>
    );
};
