import React, { FC } from "react";
import { FormControlLabel, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material";
import { FixMeLater } from "src/types";

interface AllocatorCompanyOptionsProps {
    formData: FixMeLater;
    onFormDataChange: (newData: FixMeLater) => void;
}

const AllocatorCompanyOptions: FC<AllocatorCompanyOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    const isOtherDelimiter = 
        formData?.jsonOptions?.dataDelimiter !== "," && 
        formData?.jsonOptions?.dataDelimiter !== ";" &&
        formData?.jsonOptions?.dataDelimiter !== "TAB";
        
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const fieldName = name?.split(".")[1];
        onFormDataChange({
            ...formData.jsonOptions,
            [fieldName]: value,
        });
    };

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const fieldName = name?.split(".")[1];
        onFormDataChange({
            ...formData.jsonOptions,
            [fieldName]: checked,
        });
    };

    return (
        <>
            <Typography>
                Select the File Format of ASCII File
            </Typography>
            <RadioGroup
                name="allocator.dataDelimiter"
                value={formData?.jsonOptions?.dataDelimiter}
                onChange={handleRadioChange}
            >
                <FormControlLabel
                    value={","}
                    control={<Radio />}
                    label="Comma Delimited"
                />
                <FormControlLabel
                    value={"TAB"}
                    control={<Radio />}
                    label="Tab Delimited"
                />
                <FormControlLabel
                    value={";"}
                    control={<Radio />}
                    label="Semicolon Delimited"
                />
                <FormControlLabel
                    value={isOtherDelimiter ? formData?.jsonOptions?.dataDelimiter : ""}
                    control={<Radio />}
                    label="Other Delimited"
                />
                {isOtherDelimiter &&
                    <TextField
                        size="small"
                        autoComplete="off"
                        sx={{width: "50px"}}
                        value={formData?.jsonOptions?.dataDelimiter}
                        inputProps={{ maxLength: 1, style: {textAlign: "center"} }}
                        onChange={(event) => {
                            if (event.target.value.match(/^[^'" `]*$/))
                                onFormDataChange({
                                    ...formData?.jsonOptions,
                                    dataDelimiter: event.target.value,
                            })
                        }}
                    />
                }
            </RadioGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.allocateByZipCode
                        }
                        onChange={handleToggleChange}
                        name="allocator.allocateByZipCode"
                    />
                }
                label="Allocate by Zip Code"
            />
        </>
    );
};

export default AllocatorCompanyOptions;
