import React, { FC } from "react";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";
import AllocatorLanding from "./components/AllocatorLanding/AllocatorLanding";
import CompanyOptions from "src/components/CompanyOptions/CompanyOptions";
import { useAppSelector } from "src/hooks";

const Allocator: FC = () => {
    const companyOptionsOpen: boolean | undefined = useAppSelector(
        (state) => state?.Allocator?.value.companyOptionsOpen
    );

    return (
        <SnackbarProvider
            maxSnack={5}
            disableWindowBlurListener
            autoHideDuration={10000}
            iconVariant={{
                error: <InfoOutlined sx={{ marginRight: 1 }}/>,
            }}
            style={{ flexWrap: "nowrap"}}
            action={(snackbarId) => (
                <IconButton
                    color="inherit"
                    sx={{ p: 0.5, marginLeft: 1 }}
                    onClick={() => closeSnackbar(snackbarId)}
                >
                    <Close />
                </IconButton>
            )}
        >
            {companyOptionsOpen ? (
                <CompanyOptions />
            ) : (
                <AllocatorLanding />
            )}
        </SnackbarProvider>
    );
};

export default Allocator;
