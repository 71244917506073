import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { MouseEvent, useEffect, useState } from "react";
import colors from "src/styles/colors.scss";
import "./FolderNode.scss";
import FolderNodeMenu from "../FolderNodeMenu/FolderNodeMenu";
import { StyledAccordion, StyledAccordionDetails } from "./FolderNode.styled";
import {
    Company,
    FixMeLater,
    FolderNode as FolderNodeType,
    Product,
    ReturnNode as ReturnNodeType,
    State,
} from "src/types";
import { filterForReturnNodes } from "src/utils/TreeUtils";
import { useAppSelector } from "src/hooks";
import ReturnNode from "../ReturnNode/ReturnNode";
import { AccordionSummary } from "@mui/material";
import { generateUniqueKey } from "src/utils";

interface FolderNodeProps {
    expanded: boolean;
    folderNode: FolderNodeType;
    onFolderNodeClick: (id: string) => void;
    onFolderNodeDialogOpen: (folderNode: FixMeLater) => void;
}

const FolderNode: React.FC<FolderNodeProps> = ({
    expanded,
    folderNode,
    onFolderNodeClick,
    onFolderNodeDialogOpen,
}) => {
    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    if (!product) return null; // We need to show a message when this happens

    const company: Company | undefined = useAppSelector(
        (state) => state[product?.productName]?.value?.company
    );

    const state: State | undefined = useAppSelector(
        (state) => state[product!.productName]?.value.state
    );

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev
    );

    // When the drop down is closed, and we don't have a company, we don't want to show the folder node
    if (!company && !dropdownStateView) return null; // We need to show a message when this happens

    // When the drop down is open, and we don't have a state, we don't want to show the folder node
    if (!state && dropdownStateView) return null; // We need to show a message when this happens

    // ReturnNodes without retals or retal worksheets
    const filteredReturnNodes = folderNode.returnNodes.filter(
        (returnNode: ReturnNodeType) =>
            filterForReturnNodes(returnNode, folderNode, company, true)
    );

    const [folderNodeMenuAnchorEl, setFolderNodeMenuAnchorEl] =
        useState<FixMeLater>(null);

    const openFolderNodeMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (filteredReturnNodes.length !== 0)
            setFolderNodeMenuAnchorEl(event.currentTarget);
    };

    const handleCloseFolderNodeMenu = () => {
        setFolderNodeMenuAnchorEl(null);
    };

    const handleFolderNodeDialogOpen = (node: FixMeLater) => {
        setFolderNodeMenuAnchorEl(null);
        onFolderNodeDialogOpen(node);
    };

    const [returnNodes, setReturnNodes] = useState<
        (React.JSX.Element | false)[]
    >([]);

    useEffect(() => {
        let companyForFilter = company;
        let stateForFilter = state;
        let companyDisplayName = company?.name ?? "";

        if (company && !dropdownStateView) {
            companyForFilter = company;
            stateForFilter = undefined;
            companyDisplayName = company?.name;
        }

        if (state && dropdownStateView) {
            companyForFilter = undefined;
            stateForFilter = state;
            companyDisplayName = folderNode?.attributes?.displayName;
        }

        const returnNodes: (React.JSX.Element | false)[] =
            folderNode?.returnNodes
                ?.filter((returnNode: ReturnNodeType) => {
                    return filterForReturnNodes(
                        returnNode,
                        folderNode,
                        companyForFilter,
                        false,
                        true,
                        stateForFilter
                    );
                })
                ?.map((returnNode: ReturnNodeType) => {
                    return (
                        returnNode?.formStatus != "NONE" && (
                            <ReturnNode
                                key={returnNode.id}
                                returnNode={returnNode}
                                folderNode={folderNode}
                                tabKey={generateUniqueKey(
                                    folderNode?.id.toString(),
                                    returnNode,
                                    product,
                                    companyDisplayName,
                                    municipalState
                                )}
                            />
                        )
                    );
                });

        setReturnNodes(returnNodes);
    }, [company, state, folderNode]);

    const handleContextMenu = (e: MouseEvent<HTMLElement>) => {
        const folderNodeDisplayName = folderNode?.attributes?.displayName;
        if (
            folderNodeDisplayName !== "Company Information" &&
            folderNodeDisplayName !== "Summary Schedules"
        ) {
            e.preventDefault(); // prevent the default behaviour when right clicked
            openFolderNodeMenu(e);
        }
    };

    return (
        <div className="folder-node">
            {returnNodes?.length !== 0 ? (
                <StyledAccordion
                    key={folderNode?.id}
                    sx={{ background: "transparent" }}
                    expanded={expanded}
                    onClick={() => onFolderNodeClick(folderNode?.id as string)}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                style={{ color: colors.iconColor }}
                            />
                        }
                        id={`${folderNode?.id}-header`}
                        onContextMenu={handleContextMenu}
                    >
                        <div className="folder-node-container">
                            <div className="folder-node-name-container">
                                {folderNode?.attributes?.displayName}
                            </div>
                        </div>
                    </AccordionSummary>
                    <StyledAccordionDetails sx={{ color: colors.textColor }}>
                        {returnNodes}
                    </StyledAccordionDetails>
                </StyledAccordion>
            ) : (
                <StyledAccordion
                    key={folderNode?.id}
                    sx={{ background: "transparent" }}
                    expanded={false}
                    onClick={() => onFolderNodeClick(folderNode?.id as string)}
                >
                    <AccordionSummary
                        id={`${folderNode?.id}-header`}
                        onContextMenu={handleContextMenu}
                    >
                        <div className="folder-node-container">
                            <div className="folder-node-name-container">
                                {folderNode?.attributes?.displayName}
                            </div>
                        </div>
                    </AccordionSummary>
                </StyledAccordion>
            )}

            <FolderNodeMenu
                anchorEl={folderNodeMenuAnchorEl}
                onCloseFolderNodeMenu={handleCloseFolderNodeMenu}
                folderNode={folderNode}
                onFolderNodeDialogOpen={handleFolderNodeDialogOpen}
            />
        </div>
    );
};

export default FolderNode;
