import React from "react";
import { OptinsCompanyType, OptinsFilingStatus } from "src/types";

export type EfileNaicOptinsFormData = {
    agreement: boolean;
    paymentType: PAYMENT_TYPE;
    companyTypes: OptinsCompanyType[];
    selectedCompanyType?: OptinsCompanyType;
    filingStatus?: OptinsFilingStatus;
    testingYear?: number;
};

export enum PAYMENT_TYPE {
    DEBIT = "Debit",
    CREDIT = "Credit",
}

export interface EfileNaicOptinsContextState {
    formData: EfileNaicOptinsFormData,
    setFormData: React.Dispatch<React.SetStateAction<EfileNaicOptinsFormData>>;
    fetchingFilingStatus: boolean;
}

export const EfileNaicOptinsContext = React.createContext<
    EfileNaicOptinsContextState | undefined
>(undefined);
