import styled from 'styled-components';

export const UseAgreementStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UseAgreementContentStyled = styled.div`
    display: flex;
    flex-direction: column;
`;
