import { Button } from '@mui/material';
import styled from 'styled-components';

export const EfileNaicOptinsStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const EfileNaicOptinsActionContainerStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    padding: 16px;
`;

export const ActionButtonStyled = styled(Button)`
    background-color: ${({ theme }) => theme.palette.primary};
    &:hover {
        background-color: ${({ theme }) => theme.palette.secondary};
    }
`;