import React, { FC, useEffect, useState } from "react";

// MUI Components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";

// Icons
import { Cancel } from "@mui/icons-material";

// App Imports
import { generateUniqueKey } from "src/utils";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { replaceUrlParam } from "src/services/Utility";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    User,
    TabProperties,
    Company,
    Product,
    FolderNode,
    ReturnNode,
    FixMeLater,
} from "src/types";

// App Components
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import DataTable from "../DataTable/DataTable";
import PdfViewer from "../PdfViewer/PdfViewer";
import { getReturnNodeIcon } from "../ReturnNode/ReturnNodeUtil";
import TabPanel from "./TabPanel/TabPanel";

// App Styles
import "./MultiTab.scss";
import { UserService } from "src/services";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Loader from "../Loader/Loader";

const appBarStyles = {
    appBarContainer: {
        flexDirection: "row",
        bgcolor: "#354560",
    },
};

interface MultiTabProps {
    isSplitedScreen?: boolean;
    addTab?: boolean;
    tabs?: Map<
        string,
        {
            key: string;
            returnId: String;
            tabName: string;
            taxYear: string;
            companyName: string;
            tooltip: string;
            scheduleType;
            isLocked;
            component: React.ReactElement;
        }
    >;
    tabsSplitScreen?: Map<
        string,
        {
            key: string;
            returnId: String;
            tabName: string;
            taxYear: string;
            companyName: string;
            tooltip: string;
            scheduleType;
            isLocked;
            component: React.ReactElement;
        }
    >;
    setTabs?: React.Dispatch<
        React.SetStateAction<
            Map<
                string,
                {
                    key: string;
                    returnId: String;
                    tabName: string;
                    taxYear: string;
                    companyName: string;
                    tooltip: string;
                    scheduleType;
                    isLocked;
                    component: React.ReactElement;
                }
            >
        >
    >;
    addTabToSplitScreen?: (value: string) => void;
    setActiveReturn?: (value: string) => void;
    activeTabKey?: string;
}

const MultiTab: FC<MultiTabProps> = ({
    isSplitedScreen = false,
    addTab = true,
    tabs = new Map(),
    tabsSplitScreen = new Map(),
    setTabs = () => {},
    addTabToSplitScreen = () => {},
    setActiveReturn = () => {},
    activeTabKey,
}) => {
    const dispatch = useAppDispatch();

    const userService = UserService.getInstance();

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    if (!product) return null;

    const company: Company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );

    const folderNode: FolderNode = useAppSelector(
        (state) => state?.[product?.productName]?.value?.folderNode
    );

    const returnNode: ReturnNode = useAppSelector(
        (state) => state?.[product?.productName]?.value?.returnNode
    );

    const [key, setKey] = useState<string>();

    const self: User | undefined = useAppSelector(
        (state) => state?.Self
    )?.value;

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs?.tabsProperties
    );

    const splitScreen = useAppSelector((state) => state?.Tabs?.splitScreen);

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev
    );

    const municipalQuarter: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter
    );

    const selectedQuarter = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedQuarter
    );
    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });

    const userOptions = useAppSelector((state) => state?.UserOptions?.value);

    const selectedJurisdictionForSBP: number = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.selectedJurisdictionForSBP
    );

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        tabKey: string;
    } | null>(null);

    const [splitScreenTabKey, setSplitScreenTabKey] = useState<string>("");

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarStatus, setSnackbarStatus] = useState<string>("success");
    const [tabLoadingMap, setTabLoadingMap] = useState<Map<string, boolean>>(
        new Map()
    );

    const MAX_AMOUNT_TABS = 20;

    const clearTabs = () => {
        dispatch(GlobalStateActions.setSelf({ ...self!!, pendingChanges: [] }));
        dispatch(GlobalStateActions[product?.productName].setReturnNode(null));
        dispatch(GlobalStateActions[product?.productName].setFolderNode(null));
        dispatch(GlobalStateActions.setTabs({}));
        replaceUrlParam("folderNodeId", null);
        replaceUrlParam("returnNodeId", null);
        replaceUrlParam("year", null);
        replaceUrlParam("companyId", null);
        setTabs(new Map());
    };

    const generateTab = (
        company: Company,
        product: Product,
        folderNode: FolderNode,
        returnNode: ReturnNode,
        newTabKey?: string
    ) => {
        const newKey =
            newTabKey ??
            generateUniqueKey(
                folderNode?.id.toString(),
                returnNode,
                product,
                company?.name,
                municipalState
            );
        let tooltip = `${folderNode.attributes.displayName}-${product?.taxYear} - ${company?.name} : ${returnNode?.displayName}`;
        if (product.productId == 2) {
            tooltip = `${product?.taxYear} - ${company?.name} - ${municipalState} - ${selectedQuarter} : ${returnNode?.displayName}`;

            if (
                returnNode?.displayName === "General Information" ||
                returnNode?.displayName === "Actions Report" ||
                returnNode?.displayName?.includes("Rate & Fee")
            ) {
                tooltip = `${product?.taxYear} - ${company?.name} - ${municipalState} : ${returnNode?.displayName}`;
            }
        }
        const tabName = `${product?.taxYear?.toString().slice(-2)} ${
            returnNode?.displayName
        }`;
        const taxYear = product?.taxYear.toString();
        const companyName = company?.name;

        if (!tabs.has(newKey) && !tabsSplitScreen.has(newKey)) {
            if (tabs.size >= MAX_AMOUNT_TABS) {
                setSnackbarOpen(true);
                setSnackbarMessage(
                    "You have reached the maximum amount of tabs. Please close some tabs to open a new one."
                );
                setSnackbarStatus("error");
            } else {
                const newItem = {
                    key: newKey,
                    formStatus: returnNode?.formStatus,
                    tabName,
                    taxYear,
                    companyName,
                    tooltip,
                    scheduleType: returnNode?.scheduleType,
                    isLocked: returnNode?.isLocked,
                    returnId: returnNode?.id,
                    component:
                        returnNode?.scheduleType === "RETURN" ? (
                            <PdfViewer
                                documentKey={newKey}
                                isActive={false}
                                key={newKey}
                                company={{ ...company }}
                                product={{ ...product }}
                                folderNode={{ ...folderNode }}
                                returnNode={{ ...returnNode }}
                                isSplitedScreen={isSplitedScreen}
                                generateTab={generateTab}
                                tabLoadingMap={tabLoadingMap}
                                setTabLoadingMap={setTabLoadingMap}
                            />
                        ) : (
                            <DataTable
                                documentKey={newKey}
                                isActive={false}
                                key={newKey}
                                company={{ ...company }}
                                product={{ ...product }}
                                state={municipalState}
                                quarter={municipalQuarter}
                                folderNode={{ ...folderNode }}
                                returnNode={{ ...returnNode }}
                                tabLoadingMap={tabLoadingMap}
                                setTabLoadingMap={setTabLoadingMap}
                            />
                        ),
                };
                const updatedTabs = new Map(tabs);
                updatedTabs.set(newKey, newItem);
                setTabs(updatedTabs);
                setValue(updatedTabs.size - 1);
                const tabProperties: TabProperties = {
                    id: newKey,
                    isLocked: returnNode?.isLocked,
                    returnStatus: 0,
                    returnId: returnNode?.id.toString(),
                    isActive: false,
                    submissionId: returnNode?.submissionId,
                };
                dispatch(GlobalStateActions.setTab(tabProperties));
            }
        }
        setKey(newKey);
    };

    useEffect(() => {
        if (company && product && folderNode && returnNode) {
            const newKey = generateUniqueKey(
                folderNode?.id.toString(),
                returnNode,
                product,
                company?.name,
                municipalState
            );
            if (addTab) {
                generateTab(company, product, folderNode, returnNode, newKey);
            } else {
                setKey(newKey);
            }
        }
    }, [folderNode, returnNode]);

    useEffect(() => {
        setKey(activeTabKey);
    }, [activeTabKey]);

    useEffect(() => {
        if (userOptions && (company || dropdownStateView) && product) {
            const saveUserOptions = async () => {
                try {
                    await userService.setUserOptions(userOptions);
                } catch (error) {
                    console.error("Error saving user options", error);
                }
            };
            saveUserOptions();
        }
    }, [userOptions]);

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setKey(Array.from(tabs.keys())[newValue]);
    };

    useEffect(() => {
        // Find the index of the tab corresponding to the given key
        const selectedIndex = Array.from(tabs?.keys()).indexOf(key);

        // If the selected index is valid, update the active tab index
        if (selectedIndex >= 0) {
            setValue(selectedIndex);
            const selectedItem = tabs?.get(key);

            // If the selected item exists, update the active return ID
            if (selectedItem) {
                setActiveReturn(selectedItem?.returnId);
            }
        }
    }, [key, tabs]);

    const pendingChangesOnTab = (key: string) => {
        return self?.pendingChanges?.find((tab) => tab === key);
    };

    const savingChangesOnTab = (key: string) => {
        return self?.pendingChanges?.find((tab) => tab === `${key}*`);
    };

    const handleDeleteClick = (
        index: number,
        key: string,
        event?: React.MouseEvent<HTMLDivElement, MouseEvent>,
        removeTabProperties = true
    ): Boolean => {
        if (
            (pendingChangesOnTab(key) &&
                !window.confirm(
                    `¨${
                        tabs.get(key).tabName
                    }¨ document have unsaved changes. Do you really want to leave?`
                )) ||
            (savingChangesOnTab(key) &&
                !window.confirm(
                    `changes on "${
                        tabs.get(key).tabName
                    }" document are currently being saved. Closing this window could result in data loss. Are you sure you want to leave?`
                ))
        ) {
            return false;
        } else {
            dispatch(GlobalStateActions.removePendingChange(key));
            if (removeTabProperties)
                dispatch(GlobalStateActions.removeTab(key));
            dispatch(
                GlobalStateActions[product?.productName].setReturnNode(null)
            );
            dispatch(
                GlobalStateActions[product?.productName].setFolderNode(null)
            );
            replaceUrlParam("folderNodeId", null);
            replaceUrlParam("returnNodeId", null);
            replaceUrlParam("year", null);
            replaceUrlParam("companyId", null);
            const updatedTabs = new Map(tabs);
            updatedTabs.delete(key);
            const tabsArray = Array.from(updatedTabs.keys());
            setTabs(updatedTabs);
            let newValue =
                value >= updatedTabs.size
                    ? updatedTabs.size - 1
                    : value > index
                    ? value - 1
                    : value;
            setKey(tabsArray[newValue]);
            setValue(newValue);
        }
        event?.stopPropagation();
        return true;
    };

    const handleCloseAllClick = () => {
        for (const [index, tabKey] of Array.from(tabs.keys()).entries()) {
            if (!handleDeleteClick(index, tabKey, undefined)) {
                break;
            }
        }
    };

    useEffect(() => {
        if (tabsProperties) {
            // Iterate over the current tabs
            Array.from(tabs.keys()).forEach((tabKey, index) => {
                // Check if the tab does not have a corresponding property in tabsProperties
                if (!tabsProperties[tabKey]) {
                    // Call handleDeleteClick to remove the corresponding tab
                    handleDeleteClick(index, tabKey, undefined);
                }
            });
        }
    }, [tabsProperties, tabs]);

    const determineTabTextColor = (currentYear, tabYear, isSameCompany) => {
        if (tabYear == currentYear) {
            return isSameCompany ? "white" : "yellow";
        } else {
            return isSameCompany ? "yellow" : "orange";
        }
    };

    const handleRightClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        mapKey: string
    ) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                      tabKey: mapKey,
                  }
                : null
        );
        setSplitScreenTabKey(mapKey);
    };

    const handleMenuClose = () => {
        setContextMenu(null);
    };

    const handleSplitScreen = (e) => {
        addTabToSplitScreen(splitScreenTabKey);
        dispatch(GlobalStateActions.setSplitScreen(true));
        const selectedIndex = Array.from(tabs.keys()).indexOf(
            splitScreenTabKey
        );
        handleDeleteClick(selectedIndex, splitScreenTabKey, e, false);
        handleMenuClose();
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                maxHeight: "100%",
            }}
        >
            <AppBar
                position="static"
                className="app-bar-container-class"
                sx={appBarStyles.appBarContainer}
            >
                <Tabs
                    variant={tabs.size > 0 ? "scrollable" : "fullWidth"}
                    value={value}
                    scrollButtons
                    onChange={handleChange}
                    textColor="inherit"
                    TabIndicatorProps={{
                        style: { display: "none" },
                    }}
                >
                    {Array.from(tabs).map(([mapKey, item], index) => (
                        <Tab
                            onContextMenu={(e) => handleRightClick(e, mapKey)}
                            className={
                                key === mapKey ? "tab-active" : "tab-unactive"
                            }
                            sx={{
                                fontWeight:
                                    key === mapKey ||
                                    pendingChangesOnTab(mapKey)
                                        ? "bold"
                                        : "",
                            }}
                            key={index}
                            label={
                                <Tooltip
                                    title={item.tooltip}
                                    placement="bottom"
                                >
                                    <div className="tab-header">
                                        <div className="return-node-icon-container">
                                            {getReturnNodeIcon(
                                                item?.formStatus,
                                                tabsProperties[item?.key]
                                                    ?.isLocked,
                                                Boolean(
                                                    tabsProperties[item?.key]
                                                        ?.submissionId
                                                )
                                            )}
                                        </div>

                                        <div
                                            className="tab-name"
                                            style={{
                                                color: determineTabTextColor(
                                                    product.taxYear,
                                                    item.taxYear,
                                                    company?.name ===
                                                        item?.companyName
                                                ),
                                            }}
                                        >
                                            {(pendingChangesOnTab(mapKey)
                                                ? "* "
                                                : "") + item.tabName}
                                        </div>

                                        <div
                                            className="close-button"
                                            data-testid="close-button"
                                            onClick={(e) => {
                                                if (
                                                    tabLoadingMap
                                                        .get(mapKey)
                                                        ?.valueOf() !== true
                                                ) {
                                                    handleDeleteClick(
                                                        index,
                                                        item.component.key ??
                                                            "",
                                                        e
                                                    );
                                                }
                                            }}
                                        >
                                            {tabLoadingMap
                                                .get(mapKey)
                                                ?.valueOf() !== true ? (
                                                <Cancel fontSize="small" />
                                            ) : (
                                                <Loader
                                                    color="white"
                                                    size={16}
                                                ></Loader>
                                            )}
                                        </div>
                                    </div>
                                </Tooltip>
                            }
                        />
                    ))}
                </Tabs>
                {tabs && tabs.size > 0 && (
                    <Tooltip title={"Close all tabs"} placement="bottom">
                        <IconButton
                            aria-label="Close all tabs"
                            className="close-all-button"
                            data-testId="close-all-tabs"
                            onClick={() => handleCloseAllClick()}
                        >
                            <Cancel
                                fontSize="medium"
                                sx={{
                                    fill: "white",
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </AppBar>
            {Array.from(tabs.keys()).map((item, index) => {
                return (
                    <TabPanel key={item} value={value} index={index}>
                        {React.cloneElement(tabs.get(item).component, {
                            isActive: index === value,
                            isSplitedScreen: isSplitedScreen,
                            initJurisdiction: selectedJurisdictionForSBP,
                            generateTab: generateTab,
                            tabLoadingMap: tabLoadingMap,
                            setTabLoadingMap: setTabLoadingMap,
                        })}
                    </TabPanel>
                );
            })}
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarStatus}
            />
            {tabs.size > 1 && (
                <Menu
                    open={contextMenu !== null}
                    onClose={handleMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? {
                                  top: contextMenu.mouseY,
                                  left: contextMenu.mouseX,
                              }
                            : undefined
                    }
                >
                    {!isSplitedScreen && (
                        <MenuItem onClick={handleSplitScreen}>
                            Open in Split Screen
                        </MenuItem>
                    )}
                    {splitScreen && (
                        <MenuItem
                            onClick={() => {
                                handleCloseAllClick();
                                dispatch(
                                    GlobalStateActions.setSplitScreen(false)
                                );
                                handleMenuClose();
                            }}
                        >
                            Close Split Screen
                        </MenuItem>
                    )}
                </Menu>
            )}
        </Box>
    );
};

export default MultiTab;
