import React, { FC, useEffect, useState } from "react";
import MultiTab from "src/components/MultiTab/MultiTab";
import { Sync, SyncDisabled } from "@mui/icons-material";
import "./MultiTabContainer.scss";
import { Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { Product } from "src/types";

const MultiTabContainer: FC = () => {
    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value
    );

    if (!product) return null;

    const [activeReturn, setActiveReturn] = useState<string>("");
    const [activeReturnSplitScreen, setActiveReturnSplitScreen] =
        useState<string>("");

    const [tabs, setTabs] = useState<Map<string, Tab>>(new Map());

    const [tabsSplitScreen, setTabsSplitScreen] = useState<Map<string, Tab>>(
        new Map()
    );

    const [activeTabKey, setActiveTabKey] = useState<string>("");
    const [activeTabKeySplitScreen, setActiveTabKeySplitScreen] =
        useState<string>("");

    const splitScreen = useAppSelector((state) => state.Tabs.splitScreen);

    const isSynchronizedScroll = useAppSelector(
        (state) =>
            state?.[product?.productName]?.value?.synchronizedScroll
                ?.isSynchronized
    );

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });

    const dispatch = useAppDispatch();

    const addTabToSplitScreen = (mapKey: string) => {
        const tab = tabs.get(mapKey);
        if (tab) {
            setTabsSplitScreen(new Map(tabsSplitScreen).set(mapKey, tab));
            // Set the key to the new tab
            setActiveTabKeySplitScreen(mapKey);
        }
    };

    useEffect(() => {
        setTabs(new Map());
        setTabsSplitScreen(new Map());
    }, [dropdownStateView]);

    useEffect(() => {
        if (tabsSplitScreen.size === 0 || tabs.size === 0) {
            dispatch(GlobalStateActions.setSplitScreen(false));
        }
        if (tabsSplitScreen.size > 0 && tabs.size > 0) {
            dispatch(GlobalStateActions.setSplitScreen(true));
        }
        if (tabs.size === 0 && tabsSplitScreen.size > 0) {
            const newTabs = new Map(tabsSplitScreen);
            const activeKeyTab =
                Array.from(newTabs.entries()).find(
                    ([, tab]) => tab.returnId === activeReturnSplitScreen
                )?.[0] || "";
            setTabs(newTabs);
            setTabsSplitScreen(new Map());
            setActiveTabKey(activeKeyTab);
        }
    }, [tabsSplitScreen, tabs]);

    useEffect(() => {
        dispatch(
            GlobalStateActions[
                product?.productName
            ]?.setSynchronizedScrollIsEnabled(
                splitScreen && activeReturn === activeReturnSplitScreen
            )
        );
    }, [splitScreen, activeReturn, activeReturnSplitScreen]);

    return (
        <div data-testid="multi-tab-container" className="container">
            <div
                data-testid="main-tab"
                className="main-tab"
                style={{
                    // Used display none instead of conditional rendering because if a user clicks on a return when there are not any open returns
                    // the component will not render and the user will not be able to see the return
                    // This way the component will render but will not be visible if tabs is empty.
                    display: tabs.size === 0 ? "none" : "block",
                    width: splitScreen ? "50%" : "100%",
                }}
            >
                <MultiTab
                    tabs={tabs}
                    tabsSplitScreen={tabsSplitScreen}
                    setTabs={setTabs}
                    addTabToSplitScreen={addTabToSplitScreen}
                    setActiveReturn={setActiveReturn}
                    activeTabKey={activeTabKey}
                />
            </div>
            {tabsSplitScreen.size > 0 && (
                <>
                    <div className={"split-tab"} style={{ width: "50%" }}>
                        <MultiTab
                            isSplitedScreen={true}
                            tabs={tabsSplitScreen}
                            setTabs={setTabsSplitScreen}
                            addTab={false}
                            setActiveReturn={setActiveReturnSplitScreen}
                            activeTabKey={activeTabKeySplitScreen}
                        />
                    </div>
                    {activeReturn === activeReturnSplitScreen && (
                        <Tooltip
                            title={
                                isSynchronizedScroll
                                    ? "desynchronized "
                                    : "synchronized "
                            }
                        >
                            <button
                                data-testid="sync-button"
                                onClick={() =>
                                    dispatch(
                                        GlobalStateActions[
                                            product?.productName
                                        ]?.setSynchronizedScrollIsSynchronized(
                                            !isSynchronizedScroll
                                        )
                                    )
                                }
                                className="sync-button"
                            >
                                {isSynchronizedScroll ? (
                                    <SyncDisabled className="sync-icon" />
                                ) : (
                                    <Sync className="sync-icon" />
                                )}
                            </button>
                        </Tooltip>
                    )}
                </>
            )}
        </div>
    );
};

export default MultiTabContainer;

// Define the Tab type
interface Tab {
    key: string;
    returnId: String;
    tabName: string;
    taxYear: string;
    companyName: string;
    tooltip: string;
    scheduleType: string;
    isLocked: boolean;
    component: React.ReactElement;
}
