import { FixMeLater } from ".";

export enum ToolbarItemName {
    OPEN_PRIOR_YEARS_RETURN = "Open Prior Year's Return",
    INSTRUCTIONS = "Instructions",
    DOWNLOAD = "Download",
    SAVE = "Save",
    PAYMENT_REQUEST = "Payment Request",
    ATTACHMENTS = "Attachments",
    OPTINS_EXPORT = "Optins Export",
    OPTINS_EXPORT_NOT_AVAILABLE = "Optins Export ( Not Available yet )",
    EFILE_NAIC_OPTINS = "Efile via NAIC Optins",
    EFILE_NAIC_OPTINS_NOT_AVAILABLE = "Efile via NAIC Optins ( Not Available yet )",
    EFILE_VIA_EMAIL = "Efile Via Email",
    EFILE_VIA_EMAIL_NOT_AVAILABLE = "Efile Via Email ( Not Available yet )",
    EFILE = "Efile",
    EFILE_NOT_AVAILABLE = "Efile ( Not Available yet )",
    NOTES = "Notes",
    WEB_LOGIN = "Web Login",
    PRINT = "Print",
    WEB_LINK = "Web Link",
}

export type ToolbarItem = {
    position: number;
    icon: FixMeLater;
    title: ToolbarItemName;
    onClick: () => void;
    isEnabled: boolean;
    isActive: boolean;
    isValid: boolean;
};
