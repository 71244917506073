import { FieldTemplate, ReturnPage } from "src/types";

export const getFieldId = (
    fieldTemplate: FieldTemplate,
    returnPage: ReturnPage
) => {
    return `page${returnPage.attributes.pageOrder}type${fieldTemplate.fieldType}id${fieldTemplate.id}template${returnPage.attributes.templateId}`;
};

export const isNumber = (str) => {
    return !isNaN(str) && !isNaN(parseFloat(str));
};
